import styled, { css } from 'styled-components'

const TextArea = styled.textarea`
  ${({ theme }) => css`
    border-radius: 5px;
    outline: none;
    padding: 10px 15px;
    font-size: ${theme.sizes.small};
    color: ${theme.colors.white};
    width: 100%;
    resize: vertical;
    background: transparent;
    border: 1px solid ${theme.colors.white};
    transition: border-color 0.3s;

    &:focus {
      border-color: ${theme.colors.primary};
    }

    &:disabled {
      border-color: ${theme.colors.gray};
    }
  `}
`

export default TextArea
