import { useState, useEffect, useMemo, useCallback } from 'react'
import { FiPlus, FiX } from 'react-icons/fi'
import { Link, useHistory, useParams } from 'react-router-dom'

import BreadCrumb from 'components/BreadCrumb'
import Button from 'components/Button'
import Form from 'components/Form'
import FormInputs from 'components/FormInputs'
import Input from 'components/Input'
import Label from 'components/Label'
import PageTitle from 'components/PageTitle'
import InputSelect from 'components/Select'
import ToggleSwitch from 'components/ToggleSwitch'
import checkEmptyString from 'helpers/check-empty-string'
import Plan from 'models/plan'
import { getAllCharges } from 'services/charges'
import {
  createPlan,
  getplanById,
  updatePlan as updatePlanService
} from 'services/plans'
import Swal from 'sweetalert2'

import { Container, FormButtons, PerksContainer } from './styles'

interface CreateAndEditPlanProps {
  planId: string
}

export default function CreateAndEditPlan() {
  const [title, setTitle] = useState<string>()
  const [advantage, setAdvantage] = useState([] as string[])
  const [advantageEmpty, setAdvantageEmpty] = useState('')
  const [price, setPrice] = useState<number>()
  const [courtesy, setCourtesy] = useState(false)
  const [free_period, setFreePeriod] = useState<number>()
  const [is_active, setIsActive] = useState(true)
  const [timePayPlan, setTimePayPlan] = useState<Plan>()
  const [selectedTimePay, setSelectedTimePay] = useState(
    [] as {
      id: string
      label: string
    }[]
  )
  const { planId } = useParams<CreateAndEditPlanProps>()
  const history = useHistory()

  const isEditting = useMemo(() => {
    if (planId) {
      return true
    }

    return false
  }, [planId])

  const getPlan = useCallback(async () => {
    if (planId) {
      try {
        const plan = await getplanById(planId)
        if (plan) {
          setSelectedTimePay([
            { id: plan.time_pay.id, label: plan.time_pay.title }
          ])
        }

        setTitle(plan.title)
        setPrice(plan.price)
        setFreePeriod(plan.free_period)
        setAdvantage(plan.advantage)
        setCourtesy(plan.courtesy)
      } catch (error) {
        Swal.fire({
          title: 'Erro',
          text: 'Houve um erro ao buscar o plano. ' + error.message,
          icon: 'error'
        })
      }
    }
  }, [planId])

  const timePayPlanToBeShown = useMemo(() => {
    if (timePayPlan && timePayPlan.data.length > 0) {
      return timePayPlan.data.map((tag) => ({
        label: tag.title,
        value: `${tag.id}`
      }))
    }

    return []
  }, [timePayPlan])

  const fetchTimePay = async () => {
    const timePayId = await getAllCharges()

    setTimePayPlan(timePayId)
  }
  useEffect(() => {
    fetchTimePay()
  }, [])

  const addBenefit = () => {
    if (advantage === null) {
      setAdvantage([advantageEmpty])
    } else {
      setAdvantage([...advantage, advantageEmpty])
      setAdvantageEmpty('')
    }
  }

  const removeAdvantage = (indexRemove: number) => {
    setAdvantage(advantage.filter((item, index) => indexRemove !== index))
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      addBenefit()
    }
  }

  const createPlans = async (event: React.FormEvent) => {
    event.preventDefault()

    try {
      if (checkEmptyString(title)) {
        throw new Error('Informe um nome válido para o título.')
      }
      await createPlan({
        title,
        price: courtesy === false ? Math.floor(price) : 0,
        time_pay_id: courtesy === false ? selectedTimePay[0].id : null,
        advantage,
        courtesy,
        free_period: Math.floor(free_period),
        is_active
      })

      Swal.fire({
        title: '',

        text: 'Plano criado com sucesso!',

        icon: 'success'
      })

      goToPlans()
    } catch (error: any) {
      Swal.fire({
        title: 'Erro',

        text: 'Houve um erro ao criar a plano. ' + error.message,

        icon: 'error'
      })
    }
  }

  const updatePlan = async (event: React.FormEvent) => {
    event.preventDefault()

    try {
      const chargesToBeUpdated = {
        title,
        price: courtesy === false ? Math.floor(price) : 0,
        time_pay_id: selectedTimePay[0].id,
        advantage,
        courtesy,
        free_period: Math.floor(free_period),
        is_active
      }

      await updatePlanService(planId, chargesToBeUpdated)
      Swal.fire({
        title: 'Sucesso!',
        text: 'Plano editado com sucesso!',
        icon: 'success'
      })
      history.push('/plans')
    } catch (error: any) {
      Swal.fire({
        title: 'Erro',
        text: 'Houve um erro ao editar o plano. ' + error.message,
        icon: 'error'
      })
    }
  }

  const goToPlans = () => {
    history.push('/plans')
  }

  useEffect(() => {
    getPlan()
  }, [getPlan])

  return (
    <Container>
      <BreadCrumb
        crumbs={[
          <Link key={1} to="/home">
            Início
          </Link>,

          <Link key={2} to="/plans">
            Planos
          </Link>,

          <span key={3}>{isEditting ? 'Editar' : 'Criar'} Planos</span>
        ]}
      />

      <PageTitle>{isEditting ? 'Editar' : 'Criar'} Planos</PageTitle>

      <Form>
        <FormInputs>
          <Label className="required" htmlFor="title" value={title}>
            Título
          </Label>

          <Input
            id="title"
            required
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </FormInputs>

        <PerksContainer>
          <FormInputs>
            <Label className="required" htmlFor="title">
              Vantagens
            </Label>
            <div>
              <Input
                id="advantage"
                required
                value={advantageEmpty}
                onChange={(e) => setAdvantageEmpty(e.target.value)}
                onKeyPress={handleKeyPress}
              />
              <button type="button" onClick={addBenefit}>
                <FiPlus />
              </button>
            </div>
          </FormInputs>

          <div>
            {(advantage || []).map((ben, index) => (
              <div key={index}>
                <span>{ben}</span>
                <button onClick={() => removeAdvantage(index)} type="button">
                  <FiX />
                </button>
              </div>
            ))}
          </div>
        </PerksContainer>
        <ToggleSwitch
          label="Cortesia"
          setToggleState={setCourtesy}
          isActive={courtesy}
        />

        <div className="dropdownInput">
          <span className="expirate-row">
            <Label htmlFor="price">Preço</Label>

            <Input
              id="Tipo"
              type="number"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
          </span>
          <span className="entire-row">
            <InputSelect
              title="Tipo de cobrança"
              options={timePayPlanToBeShown}
              value={selectedTimePay}
              onChange={(options) => {
                options &&
                  !window.isNaN(options.length) &&
                  setSelectedTimePay(
                    options.map((opt) => ({
                      label: opt.label,
                      id: `${opt.value}`
                    }))
                  )
              }}
            />
          </span>
        </div>

        <FormInputs>
          <Label className="required" htmlFor="title">
            Período de dias de acesso grátis
          </Label>

          <Input
            id="title"
            value={free_period}
            onChange={(e) => setFreePeriod(e.target.value)}
          />
        </FormInputs>

        <FormButtons>
          <Button type="button" className="danger">
            Cancelar
          </Button>

          <Button
            className="success"
            type="button"
            onClick={(e) => (isEditting ? updatePlan(e) : createPlans(e))}
          >
            Salvar
          </Button>
        </FormButtons>
      </Form>
    </Container>
  )
}
