import User from 'models/user'
import { getCurrentUser as getCurrentUserService } from 'services/users'

const getCurrentlUser = async (): Promise<User> => {
  let user = {} as User

  if (!window.localStorage.getItem('current-sda-user')) {
    user = await getCurrentUserService()
    window.localStorage.setItem('current-sda-user', JSON.stringify(user))
  } else {
    user = JSON.parse(window.localStorage.getItem('current-sda-user') || '')
  }

  return user
}

export default getCurrentlUser
