import React from 'react'
import { Switch, Route, Redirect, RouteProps } from 'react-router-dom'

import AuthLayout from 'components/Layouts/AuthLayout'
import DefaultLayout from 'components/Layouts/DefaultLayout'
import { useAuth } from 'context/AuthContext'
import Authors from 'pages/Authors'
import CreateAndEditAuthor from 'pages/Authors/CreateAndEdit'
import Categories from 'pages/Categories'
import CreateAndEditCategory from 'pages/Categories/CreateAndEdit'
import Charges from 'pages/Charges'
import CreateAndEditChargesPlan from 'pages/Charges/CreateAndEditCharges'
import Contents from 'pages/Contents'
import CreateAndEditContent from 'pages/Contents/CreateAndEditContent'
import Coupon from 'pages/Coupon'
import CreateAndEditCoupon from 'pages/Coupon/CreateAndEditCoupon'
import Login from 'pages/Login'
import Plans from 'pages/Plans'
import CreateAndEditPlan from 'pages/Plans/CreateAndEdit'
import Profile from 'pages/Profile'
import Trail from 'pages/Trail'
import CreateAndEditTrail from 'pages/Trail/CreateAndEditTrail'
import UsersPage from 'pages/Users'
import CreateAndEditUser from 'pages/Users/createAndEditUser'
const AuthRequired = (props: RouteProps) => {
  const { user } = useAuth()

  if (!user) {
    return <Redirect to="/" />
  }

  return (
    <DefaultLayout>
      <Route {...props} />
    </DefaultLayout>
  )
}

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact>
        <AuthLayout>
          <Login />
        </AuthLayout>
      </Route>

      <AuthRequired exact path="/home" component={Profile} />

      <AuthRequired exact path="/users" component={UsersPage} />
      <AuthRequired exact path="/create-user" component={CreateAndEditUser} />
      <AuthRequired
        exact
        path="/edit-user/:userId"
        component={CreateAndEditUser}
      />

      <AuthRequired exact path="/categories" component={Categories} />
      <AuthRequired
        exact
        path="/create-category"
        component={CreateAndEditCategory}
      />
      <AuthRequired
        exact
        path="/edit-category/:categoryId"
        component={CreateAndEditCategory}
      />

      <AuthRequired exact path="/contents" component={Contents} />
      <AuthRequired
        exact
        path="/create-content"
        component={CreateAndEditContent}
      />
      <AuthRequired
        exact
        path="/edit-content/:contentId"
        component={CreateAndEditContent}
      />

      <AuthRequired exact path="/plans" component={Plans} />
      <AuthRequired exact path="/create-plan" component={CreateAndEditPlan} />
      <AuthRequired
        exact
        path="/edit-plan/:planId"
        component={CreateAndEditPlan}
      />

      {/* Cobrança */}
      <AuthRequired exact path="/charges" component={Charges} />
      <AuthRequired
        exact
        path="/create-charges"
        component={CreateAndEditChargesPlan}
      />
      <AuthRequired
        exact
        path="/edit-charges/:chargeId"
        component={CreateAndEditChargesPlan}
      />

      {/* Trail */}
      <AuthRequired exact path="/trail" component={Trail} />
      <AuthRequired exact path="/create-trail" component={CreateAndEditTrail} />
      <AuthRequired
        exact
        path="/edit-trail/:trailId"
        component={CreateAndEditTrail}
      />

      {/* Cupom */}
      <AuthRequired exact path="/coupon" component={Coupon} />
      <AuthRequired
        exact
        path="/create-coupon"
        component={CreateAndEditCoupon}
      />
      <AuthRequired
        exact
        path="/coupon-edit/:couponId"
        component={CreateAndEditCoupon}
      />

      <AuthRequired exact path="/authors" component={Authors} />
      <AuthRequired
        exact
        path="/create-author"
        component={CreateAndEditAuthor}
      />
      <AuthRequired
        exact
        path="/edit-author/:authorId"
        component={CreateAndEditAuthor}
      />
    </Switch>
  )
}
