import styled, { css } from 'styled-components'

export const Container = styled.div`
  .dropdownInput {
    width: 76%;
    display: flex;

    & > *:first-child {
      margin-right: 2%;
    }

    .expirate-row {
      margin-bottom: 10px;
      padding-bottom: 10px;
      min-width: 30%;
    }
  }
`

export const Dropdown = styled.div<InputDropdownProps>`
  ${({ theme, size }) => css`
    width: 76%;
    display: flex;
    margin-top: 30px;

    & > *:first-child {
      margin-right: 2%;
    }

    .expirate-row {
      margin-top: -30px;
      margin-bottom: 10px;
      padding-bottom: 10px;
      min-width: 30%;
    }

    input[type='date'] {
      height: 40px;
      width: 100%;
      padding: 10px;
      border-radius: 5px;
      border: solid 1px;
      background-color: #1c1c1c;
      color: white;
      ::-webkit-calendar-picker-indicator {
        filter: invert(1);
      }
    }
  `}
`

export const PerksContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 20px;
  & > div:last-child {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    > div {
      font-size: 16px;
      color: white;
      background: #444444;
      border-radius: 4px;
      display: flex;
      overflow: hidden;
      button {
        width: 30px;
        font-size: 24px;
        color: #fff;
        background: #e4672e;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      span {
        padding: 8px;
      }
    }
  }
  & > div:first-child {
    > div {
      display: flex;
      width: 100%;
      input {
        border-radius: 4px 0 0 4px;
      }
      button {
        width: 50px;
        color: var(--default-dark-gray);
        border-radius: 0 4px 4px 0;
        border: 1px solid #e4672e;
        background: #e4672e;
        font-size: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
  }
`

export const FormButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  button:not(:last-child) {
    margin-right: 20px;
  }
`
export const ContentThumbnail = styled.img`
  max-width: 600px;
  background-color: #ebebeb;
`
export const Title = styled.p`
  margin-bottom: 10px;
  font-size: var(--font-small);
  color: var(--white);
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

type InputDropdownProps = {
  size?: 'default' | 'large'
}
