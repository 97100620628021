import Label from 'components/Label'

import * as S from './styles'

type Props = {
  children: any
  text?: string
}

export default function UploadContainer({ children, text }: Props) {
  return (
    <S.LabelContainer>
      <Label className="required">{text}</Label>
      <S.ThumbnailUploadContainer>{children}</S.ThumbnailUploadContainer>
    </S.LabelContainer>
  )
}
