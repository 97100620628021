import React, { useRef, useState } from 'react'
import { Cropper } from 'react-cropper'

import Swal from 'sweetalert2'

import Button from '../Button'
import {
  CutImageContainer,
  ImageCutContainer,
  SelectImageContainer
} from './style'

import 'cropperjs/dist/cropper.css'

interface CutImageProps {
  onCutImage: (file: File) => void
  aspect?: number
}

const CutImage: React.FC<CutImageProps> = ({ onCutImage, aspect }) => {
  const [cropper, setCropper] = useState<Cropper>()

  const [initialThumbnailSrc, setInitialThumbnailSrc] = useState('')

  const [initialThumbnailExtension, setInitialThumbnailExtension] = useState('')

  const fileInput = useRef<HTMLInputElement>(null)

  const activeFileInput = () => {
    if (fileInput.current) {
      fileInput.current.click()
    }
  }

  const handleFile = (event: React.FormEvent<HTMLInputElement>) => {
    event.preventDefault()

    try {
      if (event.currentTarget) {
        const file =
          event.currentTarget.files && event.currentTarget.files.length
            ? event.currentTarget.files[0]
            : null

        if (file) {
          const fr = new FileReader()

          fr.onloadend = () => {
            setInitialThumbnailSrc(`${fr.result}`)

            setInitialThumbnailExtension((file && file.type) || '')
          }

          fr.readAsDataURL(file)
        }
      }
    } catch (e) {
      Swal.fire({
        title: 'Erro',

        text: 'Ocorreu um erro ao carregar a imagem selecionada!',

        icon: 'error'
      })
    }
  }

  const getCroppedImage = () => {
    if (cropper) {
      return cropper.getCroppedCanvas().toDataURL()
    }

    return ''
  }

  const selectImage = () => {
    const croppedImage = getCroppedImage()

    const croppedFile = dataURLtoFile(
      croppedImage,
      `thumbnail.${initialThumbnailExtension}`
    )

    onCutImage(croppedFile)
  }

  function dataURLtoFile(dataurl: string, filename: string) {
    const arr = dataurl.split(',')

    let mime = null

    if (arr && arr.length) {
      if (arr[0]) {
        mime = arr[0].match(/:(.*?);/)

        if (mime && mime.length > 1) {
          mime = mime[1]
        }
      }
    }

    const bstr = atob(arr[1])

    let n = bstr.length

    const u8arr = new Uint8Array(n)

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }

    return new File([u8arr], filename, { type: `${mime}` })
  }

  return (
    <CutImageContainer>
      <SelectImageContainer>
        {!initialThumbnailSrc && (
          <Button type="button" onClick={activeFileInput}>
            Selecionar Imagem
          </Button>
        )}

        <input
          ref={fileInput}
          style={{ display: 'none' }}
          accept=".bmp, .jpg, .jpeg, .png"
          type="file"
          onChange={handleFile}
        />
      </SelectImageContainer>

      <ImageCutContainer>
        {initialThumbnailSrc ? (
          <>
            <div className="crop">
              <Cropper
                src={initialThumbnailSrc}
                style={{ height: '50vh' }}
                onInitialized={(instance) => {
                  setCropper(instance)
                }}
                aspectRatio={aspect || 3 / 2}
                autoCropArea={100}
                zoomOnWheel={false}
                zoomable={false}
                scalable={false}
                movable={false}
                autoCrop={true}
                guides={false}
                viewMode={1}
                dragMode={'none'}
              />
            </div>

            <div className="buttons">
              <Button type="button" onClick={activeFileInput}>
                Selecionar Imagem
              </Button>

              <Button className="success" type="button" onClick={selectImage}>
                Cortar Imagem
              </Button>
            </div>
          </>
        ) : (
          <></>
        )}
      </ImageCutContainer>
    </CutImageContainer>
  )
}

export default CutImage
