import CounterInput from 'react-counter-input'
import { Link } from 'react-router-dom'

import BreadCrumb from 'components/BreadCrumb'
import Button from 'components/Button'
import Form from 'components/Form'
import FormInputs from 'components/FormInputs'
import Input from 'components/Input'
import Label from 'components/Label'
import PageTitle from 'components/PageTitle'

import { CountInput, Dropdown, FormButtons, FormGroupInput } from './styles'

export default function CreateAndEditCoupon() {
  return (
    <div>
      <BreadCrumb
        crumbs={[
          <Link key={1} to="/home">
            Início
          </Link>,

          <Link key={2} to="/coupon">
            Cupons
          </Link>,
          <span key={3}>Criar cupom de desconto</span>
        ]}
      />

      <PageTitle>Criar cupom de desconto</PageTitle>

      <Form>
        <FormInputs>
          <Label htmlFor="title" className="required">
            Código do cupom
          </Label>
          <Input id="title" required />
        </FormInputs>

        <Dropdown>
          <>
            <FormInputs>
              <Label htmlFor="acessUsers" className="required">
                Valor de desconto
              </Label>
              <Input id="acessUsers" />
            </FormInputs>

            <FormGroupInput>
              <Label htmlFor="acessUsers" className="required">
                Quantidade de cupons
              </Label>
              <CountInput>
                <CounterInput min={0} max={365} />
              </CountInput>
            </FormGroupInput>

            <FormGroupInput>
              <Label htmlFor="acessUsers" className="required">
                Dias disponíveis
              </Label>
              <CountInput>
                <CounterInput min={0} max={365} />
              </CountInput>
            </FormGroupInput>
          </>
        </Dropdown>
        <FormInputs>
          <Label htmlFor="acessUsers" className="required">
            Usuários que podem acessar
          </Label>
          <Input id="acessUsers" />
        </FormInputs>

        <FormButtons>
          <Button type="button" styleButton="secondButton">
            Cancelar
          </Button>

          <Button className="success">Salvar</Button>
        </FormButtons>
      </Form>
    </div>
  )
}
