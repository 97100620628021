import axios from 'axios'
import httpClient from 'http-client'
export const createContentData = async () => {
  const createContentDataResponse = (await httpClient.post(`samba-videos/`))
    .data

  return createContentDataResponse
}

export const outrareq = async (uploadUrl, data) => {
  const formData = new FormData()
  formData.append('file', data)

  const createContentDataResponse = await axios.post(uploadUrl, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return createContentDataResponse
}

export const getVideos = async () => {
  const getContentDataResponse = (await httpClient.get(`samba-videos/`)).data

  return getContentDataResponse
}

export const getVideosById = async (videoId) => {
  const getContentDataResponse = (
    await httpClient.get(`samba-videos/${videoId}`)
  ).data

  return getContentDataResponse
}
