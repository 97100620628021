export default [
  {
    plan_id: '1',
    title: 'MENSAL',
    description: 'Plano Mensal',
    price: 300,
    is_active: true,
    discounts: null,
    info: null
  },
  {
    plan_id: '2',
    title: 'TRIMESTRAL',
    description: 'Plano Trimestral',
    price: 900,
    is_active: true,
    discounts: null,
    info: null
  },
  {
    plan_id: '3',
    title: 'SEMESTRAL',
    description: 'Plano Semestral',
    price: 1800,
    is_active: true,
    discounts: null,
    info: null
  }
]
