import styled, { css } from 'styled-components'

export const Dropdown = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    margin-top: 30px;

    & > *:first-child {
      margin-right: 2%;
    }
  `}
`

export const CountInput = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    border-radius: 5px;
    border: solid 1px ${theme.colors.white};
    width: 95%;
    height: 4.6rem;
    > div {
      width: 100%;
      place-content: space-between;
    }
    div > input {
      color: white;
    }
  `}
`

export const FormGroupInput = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 40%;
  padding-bottom: 20px;
`

export const FormButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  button:not(:last-child) {
    margin-right: 20px;
  }
`
