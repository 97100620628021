import { useMemo, useState } from 'react'
import { AiOutlineCheck, AiOutlineEdit } from 'react-icons/ai'
import { BiErrorAlt, BiTrash } from 'react-icons/bi'
import { Link, useHistory } from 'react-router-dom'

import Box from 'components/Box'
import BreadCrumb from 'components/BreadCrumb'
import Button from 'components/Button/Button/index'
import Checkbox from 'components/Checkbox'
import PageTitle from 'components/PageTitle'
import Table from 'components/Table'
import Plan from 'models/plan'

import contents from '../../mock/contents.json'
import { Container } from './styles'

export default function Coupon() {
  const [plans, setPlans] = useState([] as Plan[])
  const [selectAll, setSelectAll] = useState<boolean>(false)
  const history = useHistory()

  const createCoupon = (): void => {
    history.push('create-coupon')
  }
  const editCoupon = (): void => {
    history.push('edit-coupon/:couponId')
  }

  const contentsToBeShown = useMemo(() => {
    return contents && contents.length
      ? contents.map((content) => ({
          selectAll: (
            <div
              style={{
                display: 'flex',
                gap: '5px'
              }}
            >
              <Checkbox />
            </div>
          ),
          id: content.content_id,
          code: content.title,
          descount: content.description,
          active: (
            <div
              style={{
                display: 'flex',
                gap: '5px'
              }}
            >
              <AiOutlineCheck size={25} />
            </div>
          ),
          actions: (
            <div
              style={{
                display: 'flex',
                gap: '5px'
              }}
            >
              <Button
                className="small danger"
                title="Editar Usuário"
                styleButton="edit"
                onClick={editCoupon}
              >
                <div>
                  <AiOutlineEdit className="icon-danger" />
                </div>
              </Button>
              <Button
                className="small danger"
                title="Atenção Usuário"
                styleButton="attencion"
              >
                <div>
                  <BiErrorAlt className="icon-danger" />
                </div>
              </Button>
              <Button
                className="small danger"
                title="Excluir Usuário"
                styleButton="danger"
              >
                <div>
                  <BiTrash className="icon-danger" />
                </div>
              </Button>
            </div>
          )
        }))
      : []
  }, [plans])

  return (
    <Container>
      <BreadCrumb
        crumbs={[
          <Link key={1} to="/home">
            Início
          </Link>,
          <span key={2}>Cupons</span>
        ]}
      />

      <PageTitle>Cupons</PageTitle>

      <Box padding="0 0 20px 0">
        <Button onClick={createCoupon}>CRIAR CUPOM DE DESCONTO</Button>
      </Box>

      <Table
        headersConfig={[
          {
            headerLabel: <Checkbox />,
            propName: 'selectAll'
          },
          {
            headerLabel: <span>Código cupom</span>,
            propName: 'code'
          },
          {
            headerLabel: <span>Desconto</span>,
            propName: 'descount'
          },
          {
            headerLabel: <span>Ativo</span>,
            propName: 'active'
          },
          {
            headerLabel: <span>Ações</span>,
            propName: 'actions'
          }
        ]}
        itemsToShow={contentsToBeShown}
        emptyListMessage={'Não foram encontrados planos cadastradas!'}
      />
    </Container>
  )
}
