import { useCallback, useEffect, useMemo, useState } from 'react'
import { AiOutlineDownload } from 'react-icons/ai'
import { Link, useHistory, useParams } from 'react-router-dom'

import BreadCrumb from 'components/BreadCrumb'
import Button from 'components/Button'
import CutImage from 'components/CutImage'
import Form from 'components/Form'
import { FormGroupInput } from 'components/FormGroup/styles'
import FormGroup from 'components/FormInputs'
import Input from 'components/Input'
import Label from 'components/Label'
import PageTitle from 'components/PageTitle'
import InputSelect from 'components/Select'
import SelectSambaVideosContent from 'components/SelectSambaVideosContent'
import ToggleSwitch from 'components/ToggleSwitch'
import UploadContainer from 'components/UploadContainer'
import { hideModal, showModal } from 'helpers/modal'
import Authors from 'models/authors'
import Categories from 'models/categories'
import Category from 'models/category'
import { CourseThumbnail } from 'pages/Trail/CreateAndEditTrail/styles'
import { getAllAuthors, getAuthor as getAuthorServices } from 'services/authors'
import {
  getAllCategories,
  getCategory as getCategoyService
} from 'services/categories'
import {
  createContent as createContentServices,
  getContent as getContentService,
  updateContent as updateContentService
} from 'services/contents'
import { createPhoto, sendPhoto } from 'services/photos'
import { getVideosById } from 'services/video'
import Swal from 'sweetalert2'
import { v4 as uuidv4 } from 'uuid'

import { FormButtons, ThumbnailContainer } from './styles'
interface CreateAndEditContentProps {
  contentId: string
}
export default function CreateAndEditContent() {
  const history = useHistory()
  const { contentId } = useParams<CreateAndEditContentProps>()
  const [title, setTitle] = useState('')
  const [showOnLandingPage, setShowOnLandingPage] = useState(false)
  const [isHighlight, setIsHighlight] = useState(false)
  const [thumbnail, setThumbnail] = useState('')
  const [highlightThumbnail, setHighlightThumbnail] = useState('')
  const [thumbnailFileToUpload, setThumbnailFileToUpload] = useState<File>()
  const [thumbnailHightlightFileToUpload, setThumbnailHightlightFileToUpload] =
    useState<File>()
  const [categories, setCategories] = useState<Categories>()
  const [authors, setAuthors] = useState<Authors>()
  const [authorsSelected, setAuthorsSelected] = useState(
    {} as { label: string; value: string }
  )
  const [categoriesSelected, setCategoriesSelected] = useState(
    [] as { label: string; value: string }[]
  )

  const [videoReference, setVideoReference] = useState('')
  const [content, setContent] = useState('')
  const [thumbFileKey, setThumbFileKey] = useState(
    {} as { uploadUrl: string; key: string }
  )
  const [highlightFileKey, setHighlightFileKey] = useState<{
    uploadUrl: string
    key: string
  }>()

  const onCutImage = async (file: File) => {
    if (file) {
      setThumbnailFileToUpload(file)
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => setThumbnail(`${reader.result}`)
      try {
        const data = {
          image_name: uuidv4(),
          folder_name: 'Contents'
        }
        const fileUrl = await createPhoto(data)
        if (fileUrl) {
          setThumbFileKey(fileUrl)
        }
      } catch (error) {
        Swal.fire({
          title: 'Erro',
          text: 'Houve um erro ao criar a imagem. ' + error,
          icon: 'error'
        })
      }
      hideModal()
    }
  }
  const onCutHighlightImage = async (file: File) => {
    if (file) {
      setThumbnailHightlightFileToUpload(file)
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => setHighlightThumbnail(`${reader.result}`)

      try {
        const data = {
          image_name: uuidv4(),
          folder_name: 'Contents'
        }
        const fileUrl = await createPhoto(data)
        if (fileUrl) {
          setHighlightFileKey(fileUrl)
        }
      } catch (error) {
        Swal.fire({
          title: 'Erro',
          text: 'Houve um erro ao criar a imagem. ' + error,
          icon: 'error'
        })
      }
      hideModal()
    }
  }

  const selectImage = (cutImage) => {
    showModal({
      title: 'Selecionar Imagem de Capa mobile',
      content: <CutImage onCutImage={cutImage} />,
      styleModal: 'large'
    })
  }

  const selectContent = () => {
    showModal({
      title: 'Selecionar Conteúdo',
      content: <SelectSambaVideosContent setReference={setVideoReference} />
    })
  }

  const getContentById = useCallback(async () => {
    if (contentId) {
      const content = await getContentService(contentId)
      if (content) {
        content.category.map((item: Category) => {
          if (item) {
            setTimeout(() => {
              setCategoriesSelected([{ label: item.title, value: item.id }])
            }, 1)
          }
        })
      }
      setTitle(content.title)
      setIsHighlight(content.is_highlighted)
      setShowOnLandingPage(content.is_landing_page)
      setContent(content.reference.toString())
      setAuthorsSelected({
        label: content.author.title,
        value: content.author.id
      })
      setCategoriesSelected(content.category)
      setHighlightThumbnail(content.thumbnail_highlighted)
      setThumbnail(
        `${process.env.REACT_APP_AWS_S3_IMAGE_URL}` + content.thumbnail
      )
    }
  }, [])

  const getCategories = async () => {
    const allCategories = await getAllCategories()

    if (allCategories.data && allCategories.data.length >= 0) {
      setCategories(allCategories)
    }
  }

  const getAuthors = async () => {
    const allAuthors = await getAllAuthors()

    if (allAuthors.data && allAuthors.data.length >= 0) {
      setAuthors(allAuthors)
    }
  }

  const getContent = useCallback(async () => {
    const content = await getVideosById(videoReference)
    if (content) {
      setContent(content.files[content.files.length - 1].url)
    }
  }, [videoReference])

  const categoriesToBeShown = useMemo(() => {
    if (categories && categories.data.length > 0) {
      return categories.data.map((tag) => ({
        label: tag.title,
        value: `${tag.id}`
      }))
    }

    return []
  }, [categories])

  const authorsToBeShown = useMemo(() => {
    if (authors && authors.data.length > 0) {
      return authors.data.map((tag) => ({
        label: tag.title,
        value: `${tag.id}`
      }))
    }

    return []
  }, [authors])

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    const idsCategory = categoriesSelected.map((categories) => categories.value)

    const contentData = {
      category_id: idsCategory,
      author_id: authorsSelected.value,
      title: title,
      reference: content,
      is_landing_page: showOnLandingPage,
      is_highlighted: isHighlight,
      is_movie: true,
      thumbnail: thumbFileKey.key,
      thumbnail_highlighted: highlightFileKey ? highlightFileKey.key : null
    }
    if (contentId) {
      try {
        await sendPhoto(thumbFileKey.uploadUrl, thumbnailFileToUpload)
        if (highlightFileKey) {
          await sendPhoto(
            highlightFileKey.uploadUrl,
            thumbnailHightlightFileToUpload
          )
        }
      } catch (error) {
        return Swal.fire({
          title: 'Erro',
          text: 'Houve um erro ao editar a imagem. ' + error,
          icon: 'error'
        })
      }
      try {
        await updateContentService(contentId, contentData)

        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Usuário editado com sucesso!'
        })

        history.push('/contents')
      } catch (e: any) {
        return Swal.fire({
          title: 'Erro',
          text: 'Houve um erro ao editar o usuário. ' + e,
          icon: 'error'
        })
      }
    } else {
      try {
        await sendPhoto(thumbFileKey.uploadUrl, thumbnailFileToUpload)
        if (highlightFileKey) {
          await sendPhoto(
            highlightFileKey.uploadUrl,
            thumbnailHightlightFileToUpload
          )
        }
        try {
          await createContentServices(contentData)

          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Usuário criado com sucesso!'
          })

          history.push('/contents')
        } catch (error) {
          Swal.fire({
            title: 'Erro',
            text: 'Houve um erro ao criar o usuário. ' + error,
            icon: 'error'
          })
        }
      } catch (error) {
        return Swal.fire({
          title: 'Erro',
          text: 'Houve um erro ao criar 123 o usuário. ' + error,
          icon: 'error'
        })
      }
    }
  }

  useEffect(() => {
    getAuthors()
    getCategories()
  }, [])

  useEffect(() => {
    getContentById()
  }, [getContentById])

  useEffect(() => {
    if (videoReference != '') {
      hideModal()
      getContent()
    }
  }, [getContent, videoReference])

  return (
    <div>
      <BreadCrumb
        crumbs={[
          <Link key={1} to="/home">
            Início
          </Link>,

          <Link key={2} to="/content">
            Conteúdo
          </Link>,
          <span key={3}>Criar conteúdo avulso</span>
        ]}
      />

      <PageTitle>
        {contentId ? 'Editar conteúdo' : 'Criar conteúdo avulso'}
      </PageTitle>

      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label htmlFor="title" className="required">
            Título
          </Label>
          <Input
            id="title"
            required
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </FormGroup>

        <FormGroup>
          <InputSelect
            title="Autor"
            options={authorsToBeShown}
            value={authorsSelected}
            closeMenuOnSelect={true}
            isMulti={false}
            onChange={(options) => {
              setAuthorsSelected({
                label: options.label,
                value: options.value
              })
            }}
          />
        </FormGroup>
        <UploadContainer text="Adicionar conteúdo">
          <Button type="button" onClick={selectContent}>
            ADIONAR CONTEUDO
          </Button>
          {(videoReference != '' || content != '') && (
            <iframe
              title="referenced-video"
              allowFullScreen
              src={content}
              height={400}
              width={600}
              frameBorder={0}
            ></iframe>
          )}
        </UploadContainer>
        <InputSelect
          title="Categorias"
          options={categoriesToBeShown}
          value={categoriesSelected}
          closeMenuOnSelect={false}
          onChange={(options) =>
            options &&
            !window.isNaN(options.length) &&
            setCategoriesSelected(
              options.map((opt) => ({ label: opt.label, value: opt.value }))
            )
          }
        />
        <ToggleSwitch
          label="Mostrar na Landing Page"
          isActive={showOnLandingPage}
          setToggleState={setShowOnLandingPage}
        />
        <ToggleSwitch
          label="Destacar conteúdo na Home"
          isActive={isHighlight}
          setToggleState={setIsHighlight}
        />
        <ThumbnailContainer>
          <FormGroupInput>
            <UploadContainer text="Miniatura">
              <Button type="button" onClick={() => selectImage(onCutImage)}>
                <AiOutlineDownload />
                SELECIONAR IMAGEM DE MINIATURA
              </Button>
              {thumbnail && <CourseThumbnail src={thumbnail} />}
            </UploadContainer>
          </FormGroupInput>
          {isHighlight && (
            <FormGroupInput>
              <UploadContainer text="Miniatura para destacar conteúdo na Home">
                <Button
                  type="button"
                  onClick={() => selectImage(onCutHighlightImage)}
                >
                  <AiOutlineDownload />
                  SELECIONAR IMAGEM DE MINIATURA
                </Button>
                {highlightThumbnail && (
                  <CourseThumbnail src={highlightThumbnail} />
                )}
              </UploadContainer>
            </FormGroupInput>
          )}
        </ThumbnailContainer>
        <FormButtons>
          <Button type="button" styleButton="secondButton">
            Cancelar
          </Button>

          <Button className="success">Salvar</Button>
        </FormButtons>
      </Form>
    </div>
  )
}
