import styled, { css } from 'styled-components'

type BorderProps = {
  styleBorder?: string
}

export const ThumbnailUploadContainer = styled.div<BorderProps>`
  ${({ theme, styleBorder = 'default' }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 1rem;
    border-radius: 5px;
    border: ${styleBorder === 'default'
      ? `solid 1px ${theme.colors.white}`
      : 'none'};
    padding: 10px 10px;
    height: auto;
    gap: 1rem;
    button {
      gap: 1rem;
      display: flex;
    }
  `}
`

export const LabelContainer = styled.div`
  margin-top: 1rem;
  width: 100%;
`
