import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'

import BreadCrumb from 'components/BreadCrumb'
import Button from 'components/Button'
import Form from 'components/Form'
import FormInputs from 'components/FormInputs'
import Input from 'components/Input'
import Label from 'components/Label'
import PageTitle from 'components/PageTitle'
import checkEmptyString from 'helpers/check-empty-string'
import {
  createCharge,
  getCharge as getChargeService,
  updateCharges as updateChargesService
} from 'services/charges'
import Swal from 'sweetalert2'

import { Container, FormButtons } from './styles'
interface CreateAndEditChargesProps {
  chargeId: string
}

export default function CreateAndEditChargesPlan() {
  const [title, setTitle] = useState('')
  const [days_to_pay, setDaysToPay] = useState<number>()
  const { chargeId } = useParams<CreateAndEditChargesProps>()

  const history = useHistory()

  const isEditting = useMemo(() => {
    if (chargeId) {
      return true
    }

    return false
  }, [chargeId])

  const getAuthor = useCallback(async () => {
    if (chargeId) {
      try {
        const charge = await getChargeService(chargeId)
        setTitle(charge.title)
        setDaysToPay(charge.days_to_pay)
      } catch (error) {
        Swal.fire({
          title: 'Erro',
          text: 'Houve um erro ao buscar a cobraça. ' + error.message,
          icon: 'error'
        })
      }
    }
  }, [chargeId])

  const createCharges = async (event: React.FormEvent) => {
    event.preventDefault()

    try {
      if (checkEmptyString(title)) {
        throw new Error('Informe um nome válido para a cobrança.')
      }

      await createCharge({
        title,

        days_to_pay: days_to_pay
      })

      Swal.fire({
        title: '',

        text: 'Cobrança criada com sucesso!',

        icon: 'success'
      })

      goToCharge()
    } catch (error: any) {
      Swal.fire({
        title: 'Erro',

        text: 'Houve um erro ao criar a cobrança. ' + error.message,

        icon: 'error'
      })
    }
  }

  const updateCharges = async (event: React.FormEvent) => {
    event.preventDefault()

    try {
      const chargesToBeUpdated = {
        title,
        days_to_pay
      }

      await updateChargesService(chargeId, chargesToBeUpdated)
      Swal.fire({
        title: 'Sucesso!',
        text: 'Autor editado com sucesso!',
        icon: 'success'
      })
      history.push('/charges')
    } catch (error: any) {
      Swal.fire({
        title: 'Erro',
        text: 'Houve um erro ao editar o autor. ' + error.message,
        icon: 'error'
      })
    }
  }

  const goToCharge = () => {
    history.push('/charges')
  }
  useEffect(() => {
    getAuthor()
  }, [getAuthor])

  return (
    <Container>
      <BreadCrumb
        crumbs={[
          <Link key={1} to="/home">
            Início
          </Link>,

          <Link key={2} to="/plans">
            Cobranças
          </Link>,

          <span key={3}>{isEditting ? 'Editar' : 'Criar'} planos</span>
        ]}
      />

      <PageTitle>{isEditting ? 'Editar' : 'Criar'} cobrança</PageTitle>

      <Form>
        <FormInputs>
          <Label className="required" htmlFor="title">
            Tipo de cobrança
          </Label>

          <Input
            id="title"
            required
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </FormInputs>

        <FormInputs>
          <Label className="required" htmlFor="title">
            Intervalo de tempo da cobrança
          </Label>

          <Input
            id="title"
            required
            value={days_to_pay}
            onChange={(e) => setDaysToPay(e.target.value)}
          />
        </FormInputs>

        <FormButtons>
          <Button type="button" styleButton="secondButton" onClick={goToCharge}>
            Cancelar
          </Button>

          <Button
            styleButton="default"
            onClick={(e) => (isEditting ? updateCharges(e) : createCharges(e))}
          >
            Salvar
          </Button>
        </FormButtons>
      </Form>
    </Container>
  )
}
