import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'

import BreadCrumb from 'components/BreadCrumb'
import Button from 'components/Button'
import Form from 'components/Form'
import FormInputs from 'components/FormInputs'
import Input from 'components/Input'
import Label from 'components/Label'
import PageTitle from 'components/PageTitle'
import checkEmptyString from 'helpers/check-empty-string'
import {
  createAuthor,
  getAuthor as getAuthorService,
  updateAuthor as updateAuthorService
} from 'services/authors'
import Swal from 'sweetalert2'

import { Container, FormButtons } from './styles'

interface CreateAndEditAuthorProps {
  authorId: string
}

export default function CreateAndEditAuthor() {
  const [title, setTitle] = useState('')
  const { authorId } = useParams<CreateAndEditAuthorProps>()

  const isEditting = useMemo(() => {
    if (authorId) {
      return true
    }

    return false
  }, [authorId])

  const getAuthor = useCallback(async () => {
    if (authorId) {
      try {
        const author = await getAuthorService(authorId)
        setTitle(author.title)
      } catch (error) {
        Swal.fire({
          title: 'Erro',
          text: 'Houve um erro ao buscar o author. ' + error.message,
          icon: 'error'
        })
      }
    }
  }, [authorId])

  const history = useHistory()

  const createAuthors = async (event: React.FormEvent) => {
    event.preventDefault()

    try {
      if (checkEmptyString(title)) {
        throw new Error('Informe um nome válido para o autor.')
      }

      await createAuthor({
        title
      })

      Swal.fire({
        title: '',

        text: 'Autor criado com sucesso!',

        icon: 'success'
      })

      history.push('/authors')
    } catch (error) {
      Swal.fire({
        title: 'Erro',

        text: 'Houve um erro ao criar o autor. ' + error.message,

        icon: 'error'
      })
    }
  }
  const updateAuthor = async (event: React.FormEvent) => {
    event.preventDefault()

    try {
      const authorToBeUpdated = {
        title
      }

      await updateAuthorService(authorId, authorToBeUpdated)
      Swal.fire({
        title: 'Sucesso!',
        text: 'Autor editado com sucesso!',
        icon: 'success'
      })
      history.push('/authors')
    } catch (error: any) {
      Swal.fire({
        title: 'Erro',
        text: 'Houve um erro ao editar o autor. ' + error.message,
        icon: 'error'
      })
    }
  }

  useEffect(() => {
    getAuthor()
  }, [getAuthor])

  return (
    <Container>
      <BreadCrumb
        crumbs={[
          <Link key={1} to="/home">
            Início
          </Link>,

          <Link key={2} to="/authors">
            Autor
          </Link>,
          <span key={3}>{isEditting ? 'Editar' : 'Criar'} Autor</span>
        ]}
      />

      <PageTitle>{isEditting ? 'Editar' : 'Criar'} Autor</PageTitle>

      <Form>
        <FormInputs>
          <Label className="required" htmlFor="title">
            Título
          </Label>

          <Input
            id="title"
            required
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </FormInputs>

        <FormButtons>
          <Button
            type="button"
            styleButton="secondButton"
            onClick={() => history.push('/authors')}
          >
            Cancelar
          </Button>

          <Button
            styleButton="default"
            onClick={(e) => (isEditting ? updateAuthor(e) : createAuthors(e))}
          >
            Salvar
          </Button>
        </FormButtons>
      </Form>
    </Container>
  )
}
