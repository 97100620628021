import styled from 'styled-components'

export const SelectSambaVideosContentContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

export const ContentUpload = styled.button`
  height: 70rem;
  width: 50%;
  background: none;
  border: solid 1px var(--default-dark-gray);
  border-radius: 15px;
  cursor: pointer;
  color: var(--primary-color);

  &:hover {
    transition: all 0.3s;
    background: var(--primary-color);
    color: white;
  }

  p {
    font-size: var(--font-large);
    margin-bottom: 10px;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const ContentListContainer = styled.div`
  width: 50%;
  height: 100%;
`

export const ContentList = styled.div`
  max-height: 70rem;
  height: 100%;
  padding: 0 15px;
  overflow-y: auto;

  & > div {
    margin: 0 auto;
  }

  & > :not(:last-child) {
    margin-bottom: 10px;
  }
`
export const Input = styled.input``
export const ContentListFilters = styled.form`
  height: 8%;
  padding: 0 15px;

  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ContentListFilter = styled.input`
  flex-grow: 1;
  padding: 10px;
  height: 43px;
  outline: none;
  border: solid 1px var(--default-dark-gray);
  border-radius: 5px;
`
