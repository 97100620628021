import type { ReactNode } from 'react'
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react'
import { useHistory } from 'react-router-dom'

import User from 'models/user'
import Swal from 'sweetalert2'

import type ISignInData from '../models/signInData'
import { signIn as signInService } from '../services/login'

type AuthContext = {
  user: string | undefined

  login: (data: ISignInData) => Promise<void>

  logout: () => void
}

type ProviderProps = {
  children: ReactNode
}

export const Context = createContext({} as AuthContext)

export default function AuthProvider({ children }: ProviderProps) {
  const [user, setUser] = useState<string | undefined>(() => {
    const token = localStorage.getItem('sjt-api-token')

    return token
  })

  const history = useHistory()

  const login = useCallback(
    async (data: ISignInData) => {
      try {
        const user = await signInService(data)

        setUser(user)

        localStorage.setItem('sjt-api-token', user.token)

        history.push('/home')
      } catch (error) {
        Swal.fire({
          title: 'Erro ao efetuar login.',

          text: 'A matrícula e a senha estão incorretas ou não existem.',

          icon: 'error',

          confirmButtonColor: '#1d2680'
        })
      }
    },

    [history]
  )

  const logout = useCallback(() => {
    setUser(undefined)

    localStorage.removeItem('sjt-api-token')

    localStorage.removeItem('sjt-user')

    localStorage.removeItem('sjt-api-token')

    localStorage.removeItem('sjt-token')

    history.push('/')
  }, [history])

  const value = useMemo(() => ({ user, login, logout }), [user, login, logout])

  return <Context.Provider value={value}>{children}</Context.Provider>
}

export function useAuth() {
  const { user, login, logout } = useContext(Context)

  return { user, login, logout }
}
