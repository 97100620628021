import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import 'react-datepicker/dist/react-datepicker.css'

import App from 'components/App'
import Loading from 'components/Loading'
import AuthProvider from 'context/AuthContext'
import { ThemeProvider } from 'styled-components'
import GlobalStyles from 'styles/global'
import theme from 'styles/theme'

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Router>
        <AuthProvider>
          <App />
        </AuthProvider>
      </Router>
      <Loading />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
