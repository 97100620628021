import httpClient from 'http-client'
import Categories from 'models/categories'
import Category from 'models/category'
import CategoryForCreate from 'models/for-create/category'
import CategoryForUpdate from 'models/for-update/category'

import { Filters } from './contents'

export const getCategory = async (categoryId: string): Promise<Category> => {
  const category = await (await httpClient.get(`categories/${categoryId}`)).data
  return category
}

export const getAllCategories = async (
  filters?: Filters
): Promise<Categories> => {
  const categories = (
    await httpClient.get('categories?is_paginated=true', { params: filters })
  ).data

  return categories
}

export const createCategory = async (
  newCategory: CategoryForCreate
): Promise<Categories> => {
  const createCategoryResponse = (
    await httpClient.post(`categories`, newCategory)
  ).data

  return createCategoryResponse
}

export const updateCategory = async (
  categoryId: string,

  newContent: CategoryForUpdate
): Promise<void> => {
  await httpClient.put(`categories/${categoryId}`, newContent)
}

export const deleteCategory = async (categoryId: string): Promise<void> => {
  await httpClient.delete(`categories/${categoryId}`)
}

export const activateOrInactivateCategory = async (
  categoryId: string,

  activate: boolean
): Promise<void> => {
  await httpClient.patch(`categories/${categoryId}/status`, {
    is_active: activate
  })
}

interface UserRequest {
  title?: string
  is_active?: boolean
  id?: string
}

export const updateCategoryById = async (
  categoryId: string,
  data: UserRequest
): Promise<Category> => {
  return (await httpClient.patch(`categories/${categoryId}`, data)).data
}
