import { useCallback, useEffect, useState } from 'react'
import { Link, useParams, useHistory } from 'react-router-dom'

import BreadCrumb from 'components/BreadCrumb'
import Button from 'components/Button'
import Form from 'components/Form'
import FormButton from 'components/FormButton'
import { FormGroupInput } from 'components/FormGroup/styles'
import Input from 'components/Input'
import Label from 'components/Label'
import PageTitle from 'components/PageTitle'
import InputSelect from 'components/Select'
import ToggleSwitch from 'components/ToggleSwitch'
import Plan from 'models/plan'
import { createUser, getUserById, updateUserById } from 'services/users'
import Swal from 'sweetalert2'

import { DateContainer, DatePlanContainer } from './styles'

interface CreateAndEditUserProps {
  userId: string
}

export default function CreateAndEditUser() {
  const [isAdmin, setIsAdmin] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [plan, setPlan] = useState(null)
  const history = useHistory()
  const { userId } = useParams<CreateAndEditUserProps>()

  const getUser = useCallback(async () => {
    if (userId) {
      const user = await getUserById(userId)
      if (user) {
        const admin = user.role === 'admin' ? true : false
        setName(user.name)
        setIsAdmin(admin)
        setEmail(user.email)
        setPlan(user.plan)
      }
    }
  }, [userId])
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()

    const typeUser = isAdmin ? 'admin' : 'user'

    const userData = {
      name: name,
      email: email,
      password: 'Senha@123',
      plan: plan,
      term_accepted: true,
      term_signature: true,
      role: typeUser
    }

    if (userId) {
      try {
        await updateUserById(userId, userData)

        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Usuário editado com sucesso!'
        })

        history.push('/users')
      } catch (e: any) {
        return Swal.fire({
          title: 'Erro',
          text: 'Houve um erro ao editar o usuário. ' + e,
          icon: 'error'
        })
      }
    } else {
      try {
        await createUser(userData)

        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Usuário criado com sucesso!'
        })

        history.push('/users')
      } catch (error) {
        Swal.fire({
          title: 'Erro',
          text: 'Houve um erro ao criar o usuário. ' + error,
          icon: 'error'
        })
      }
    }
  }

  useEffect(() => {
    getUser()
  }, [getUser])

  const options = [
    { label: 'one', value: '1', style: { color: 'red' } },
    { label: 'two', value: '2', style: { color: 'blue' } }
  ]
  return (
    <>
      <BreadCrumb
        crumbs={[
          <Link key={1} to="/home">
            Início
          </Link>,

          <Link key={2} to="/plans">
            Planos
          </Link>,

          <span key={3}>Planos</span>
        ]}
      />

      <PageTitle>{userId ? 'Editar usuário' : 'Criar usuário'}</PageTitle>

      <Form onSubmit={handleSubmit}>
        <FormGroupInput>
          <Label htmlFor="title" className="required">
            Nome
          </Label>

          <Input
            id="title"
            value={name}
            required
            onChange={(e) => setName(e.target.value)}
          />
        </FormGroupInput>

        <FormGroupInput>
          <Label htmlFor="title" className="required">
            E-mail
          </Label>

          <Input
            id="title"
            value={email}
            required
            onChange={(e) => setEmail(e.target.value)}
          />
        </FormGroupInput>
        <DatePlanContainer>
          <InputSelect title="Plano" options={options} />
          <DateContainer>
            <Label htmlFor="finishAt">Data de expiração</Label>
            <Input
              type="date"
              id="finishAtLte"
              onKeyDown={(e) => {
                e.stopPropagation()
                e.preventDefault()
                return false
              }}
            />
          </DateContainer>
        </DatePlanContainer>
        <ToggleSwitch
          isActive={isAdmin}
          setToggleState={setIsAdmin}
          label="Tornar usuário adm"
        />

        <FormButton>
          <Button type="button" styleButton="secondButton">
            Cancelar
          </Button>

          <Button type="submit" styleButton="default">
            Salvar
          </Button>
        </FormButton>
      </Form>
    </>
  )
}
