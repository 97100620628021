import styled, { css } from 'styled-components'

export const Container = styled.div``

export const ContentThumbnail = styled.img`
  max-width: 600px;
  background-color: #ebebeb;
`

export const FormButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  button:not(:last-child) {
    margin-right: 20px;
  }
`
