import styled, { css } from 'styled-components'

export const CourseThumbnail = styled.img`
  max-width: 600px;
`

export const IconContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 5px;
    border: solid 1px ${theme.colors.white};
    width: 100%;
    height: 3rem;
  `}
`

export const FormButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  button:not(:last-child) {
    margin-right: 20px;
  }
`

type InputDropdownProps = {
  size?: 'default' | 'large'
}

export const Dropdown = styled.div<InputDropdownProps>`
  ${({ theme, size }) => css`
    width: 100%;
    display: flex;
    margin-top: 30px;

    & > *:first-child {
      margin-right: 2%;
    }

    .expirate-row {
      margin-top: -30px;
      margin-bottom: 10px;
      padding-bottom: 10px;
      min-width: 30%;
    }

    input[type='date'] {
      height: 40px;
      width: 100%;
      padding: 10px;
      border-radius: 5px;
      border: solid 1px;
      background-color: #1c1c1c;
      color: white;
      ::-webkit-calendar-picker-indicator {
        filter: invert(1);
      }
    }
  `}
`

export const LessonSelectionContainer = styled.div`
  width: 100%;
`

export const SortLessonsContainer = styled.div`
  margin-bottom: 40px;
  width: 100%;
  & .selected-lessons {
    width: 100%;
    border-radius: 4px;
    border: solid 1px white;
    padding: 5px;

    & > .buttons {
      & > :not(:last-child) {
        margin-bottom: 5px;
      }

      button {
        svg {
          display: block;
        }
        &.down {
          svg {
            fill: red;
          }
        }
        &.up {
          svg {
            fill: green;
          }
        }
      }

      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
    }

    & > .lesson-title {
      flex-grow: 1;
      color: white;
      font-size: 20px;
      font-weight: 600;
    }

    & > :not(:last-child) {
      margin-right: 10px;
    }

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & > div:not(:last-child) {
    margin-bottom: 10px;
  }

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
`

export const DefaultButton = styled.button`
  background: white;
  color: white;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 15px 20px;
  font-size: 20px;
  font-weight: bold;
  border-radius: 5px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08), 0px 4px 6px rgba(50, 50, 93, 0.1);

  &.danger {
    background: red;
  }

  &.success {
    background: green;
  }

  &.info {
    background: black;
    max-height: 49px;
  }

  &.white {
    background: black;
    color: #444;
    border: solid 1px #444444;
  }

  &.warning {
    background: #ffc107;
    color: black;
  }

  &.small {
    padding: 3px 6px;
  }
`

export const ImageContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  gap: 1rem;
`
