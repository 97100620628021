import httpClient from 'http-client'
import User from 'models/user'
import UserTest from 'models/users'

import fakeUsers from '../mock/users'

export interface Filters {
  take?: number
  page?: number
  search?: string
}

interface UserResponse {
  total: number
  users: User[]
}
export const getUsers = async (filters?: Filters): Promise<UserTest> => {
  const users = (await httpClient.get('users', { params: filters })).data

  return users
}

export const getUsersConditions = async (): Promise<User[]> => {
  const users = (await httpClient.get('users/accept-conditions')).data

  return users
}
export const getUserById = async (user_id: string): Promise<User> => {
  return (await httpClient.get(`users/${user_id}`)).data
}

export const getCurrentUser = async (): Promise<User> => {
  return (await httpClient.get('users/me')).data
}

export const toAdmin = async (user_id: string): Promise<any> => {
  // return (await httpClient.patch('users/' + user_id + '/admin')).data
  return fakeUsers.map((user) => {
    if (user.user_id === user_id) {
      user.isAdmin = !user.isAdmin
    }
  })
}

export const updateStatusUser = async (
  user_id: string,
  is_active: boolean
): Promise<any> => {
  return (await httpClient.patch('users/' + user_id + '/active', { is_active }))
    .data
}

export const deleteUserById = async (user_id: string): Promise<User> => {
  return (await httpClient.delete(`users/${user_id}`)).data
}
interface UserRequest {
  name?: string
  email?: string
  password?: string
  term_accepted?: boolean
  term_signature?: boolean
  is_active?: boolean
  role?: string
}

export const updateUserById = async (
  user_id: string,
  data: UserRequest
): Promise<User> => {
  return (await httpClient.patch(`users/${user_id}`, data)).data
}

export const createUser = async (newUser: UserRequest): Promise<User> => {
  const createCategoryResponse = (await httpClient.post(`users`, newUser)).data

  return createCategoryResponse
}

export const updateUserPlan = async (data: any): Promise<any> => {
  return (await httpClient.post('payment/checkout-allow-admin', data)).data
}
