import styled, { css } from 'styled-components'

export const FormButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  button:not(:last-child) {
    margin-right: 20px;
  }
`

type InputDropdownProps = {
  size?: 'default' | 'large'
}

export const Dropdown = styled.div<InputDropdownProps>`
  ${({ theme, size }) => css`
    width: 100%;
    display: flex;
    margin-top: 30px;

    & > *:first-child {
      margin-right: 2%;
    }

    .expirate-row {
      margin-top: -30px;
      margin-bottom: 10px;
      padding-bottom: 10px;
      min-width: 30%;
    }

    input[type='date'] {
      height: 40px;
      width: 100%;
      padding: 10px;
      border-radius: 5px;
      border: solid 1px;
      background-color: #1c1c1c;
      color: white;
      ::-webkit-calendar-picker-indicator {
        filter: invert(1);
      }
    }
  `}
`

export const ThumbnailContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  gap: 1.6rem;
`
