import react, { useEffect, useState, useCallback, useMemo } from 'react'
import { AiOutlineCheck, AiOutlineClose, AiOutlineEdit } from 'react-icons/ai'
import { BiErrorAlt, BiTrash } from 'react-icons/bi'
import { Link, useHistory } from 'react-router-dom'

import Box from 'components/Box'
import BreadCrumb from 'components/BreadCrumb'
import Button from 'components/Button/Button/index'
import Checkbox from 'components/Checkbox'
import PageTitle from 'components/PageTitle'
import Table from 'components/Table'
import Charges from 'models/charges'
import { deleteCharge, getAllCharges, updateChargeById } from 'services/charges'
import { Filters } from 'services/contents'
import Swal from 'sweetalert2'

import { Container } from './styles'

const itensPerPage = 10

export default function ChargesList() {
  const [getAllChargesRes, setGetAllChargesRes] = useState<Charges>()
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [actualPage, setActualPage] = useState<any>()

  const history = useHistory()

  const createCharges = (): void => {
    history.push('create-charges')
  }

  const editCharges = useCallback(
    (chargesId: string) => {
      history.push(`edit-charges/${chargesId}`)
    },
    [history]
  )

  const removeCharge = useCallback(async (chargeId: string) => {
    Swal.fire({
      title: '<strong>Confirmação</strong>',

      html: 'Tem certeza que deseja remover esta cobrança?',

      showCancelButton: true,

      cancelButtonText: 'Cancelar',

      focusConfirm: false
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteCharge(chargeId)

          Swal.fire({
            icon: 'success',

            title: 'Sucesso!',

            text: 'Cobrança excluída com sucesso!'
          })

          await getCharges({})
        } catch (error) {
          Swal.fire({
            icon: 'error',

            title: 'Erro',

            text: `Erro ao excluir cobrança. ${
              error.response
                ? 'Esta cobrança já está associada a um conteúdo!'
                : error.message
            }`
          })
        }
      }
    })
  }, [])

  const getCharges = useCallback(
    async (filters: Filters) => {
      if (history.location.search) {
        const page = history.location.search.split('?page=')
        filters.page = parseInt(page[1])
      }

      if (filters.page === 0) {
        delete filters.page
      }
      const allCharges = await getAllCharges(filters)
      if (allCharges.meta_data) {
        const pagination = allCharges.meta_data
        setActualPage(pagination.page)
        setTotalPages(pagination.page_count)
      }
      if (allCharges.data && allCharges.data.length >= 0) {
        setGetAllChargesRes(allCharges)
      }
    },
    [history.location.search]
  )
  useEffect(() => {
    getCharges({ take: itensPerPage, page: page })
  }, [getCharges, page])

  const inactivateCharge = useCallback(
    async (chargeId: string, is_active: boolean) => {
      Swal.fire({
        title: '<strong>Confirmação</strong>',
        icon: 'question',
        html: `Tem certeza que deseja ${
          is_active ? 'ativar' : 'inativar'
        } esta cobrança?`,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        focusConfirm: false
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await updateChargeById(chargeId, { is_active: is_active })

            Swal.fire({
              icon: 'success',
              title: 'Sucesso!',
              text: `Cobrança ${
                is_active ? 'ativada com sucesso!' : 'inativada com sucesso!'
              }`
            })
            await getCharges({})
          } catch (e) {
            Swal.fire({
              icon: 'error',
              title: 'Erro ao inativar a cobrança',
              text: e.message
            })
          }
        }
      })
    },
    [getCharges]
  )

  const chargesToBeShown = useMemo(() => {
    return getAllChargesRes
      ? getAllChargesRes.data.map((charge) => ({
          selectAll: (
            <div
              style={{
                display: 'flex',

                gap: '5px'
              }}
            >
              <Checkbox />
            </div>
          ),

          id: charge.id,

          title: charge.title,

          interval: charge.days_to_pay,

          active: (
            <div
              style={{
                display: 'flex',

                gap: '5px'
              }}
            >
              {!charge.is_active ? (
                <>
                  <AiOutlineClose size={25} />{' '}
                </>
              ) : (
                <>
                  <AiOutlineCheck size={25} />
                </>
              )}
            </div>
          ),

          actions: (
            <div
              style={{
                display: 'flex',

                gap: '5px'
              }}
            >
              <Button
                className="small danger"
                title="Editar Usuário"
                styleButton="edit"
                onClick={() => editCharges(charge.id)}
              >
                <div>
                  <AiOutlineEdit className="icon-danger" />
                </div>
              </Button>

              <Button
                className="small danger"
                styleButton="attencion"
                title={!charge ? 'Inativar' : 'Ativar' + ' cobrança'}
                onClick={() => {
                  inactivateCharge(charge.id, !charge.is_active)
                }}
              >
                <div>
                  <AiOutlineClose size={25} />{' '}
                </div>
              </Button>

              <Button
                className="small danger"
                title="Excluir Usuário"
                styleButton="danger"
                onClick={() => removeCharge(charge.id)}
              >
                <div>
                  <BiTrash className="icon-danger" />
                </div>
              </Button>
            </div>
          )
        }))
      : []
  }, [editCharges, getAllChargesRes, removeCharge, inactivateCharge])

  return (
    <Container>
      <BreadCrumb
        crumbs={[
          <Link key={1} to="/home">
            Início
          </Link>,

          <span key={2}>Cobranças</span>
        ]}
      />

      <PageTitle>Cobranças</PageTitle>

      <Box padding="0 0 20px 0">
        <Button onClick={createCharges}>CRIAR NOVA COBRANÇA</Button>
      </Box>

      <Table
        headersConfig={[
          {
            headerLabel: <Checkbox />,

            propName: 'selectAll'
          },

          {
            headerLabel: <span>Cobranças</span>,

            propName: 'title'
          },

          {
            headerLabel: <span>Intervalo de tempo</span>,

            propName: 'interval'
          },

          {
            headerLabel: <span>Ativo</span>,

            propName: 'active'
          },

          {
            headerLabel: <span>Ações</span>,

            propName: 'actions'
          }
        ]}
        handleChangePage={(page) =>
          getCharges({ take: itensPerPage, page: page })
        }
        actualPage={actualPage}
        totalPages={totalPages}
        setPage={(page) => setPage(page)}
        foot
        itemsToShow={chargesToBeShown}
        emptyListMessage={'Não foram encontrados cobranças cadastradas!'}
      />
    </Container>
  )
}
