import Charge from 'models/charge'
import Charges from 'models/charges'

import httpClient from '../http-client'
import ChargesForCreate from '../models/for-create/charges'
import { Filters } from './contents'
interface ChargesCreateUpdate {
  title: string
  days_to_pay: string | number
}

const getAllCharges = async (filters?: Filters): Promise<Charges> => {
  const charges = (await httpClient.get('time-pay', { params: filters })).data

  return charges
}

export const getCharge = async (chargeId: string): Promise<Charge> => {
  const charge = await (await httpClient.get(`time-pay/${chargeId}`)).data

  return charge
}

const createCharge = async (newCharge: ChargesForCreate) => {
  const createChargeResponse = (
    await httpClient.post<any>(`time-pay`, newCharge)
  ).data

  return createChargeResponse
}

const updateCharges = async (
  chargeId: string,
  chargeToBeUpdated: ChargesCreateUpdate
): Promise<Charge> => {
  const author = (
    await httpClient.patch(`time-pay/${chargeId}`, chargeToBeUpdated)
  ).data

  return author
}

interface ChargeRequest {
  title?: string
  is_active?: boolean
  id?: string
}

export const updateChargeById = async (
  chargeId: string,
  data: ChargeRequest
): Promise<Charge> => {
  return (await httpClient.patch(`time-pay/${chargeId}`, data)).data
}

const deleteCharge = async (chargeId: string) => {
  await httpClient.delete(`time-pay/${chargeId}`)
}

export { getAllCharges, createCharge, updateCharges, deleteCharge }
