import httpClient from 'http-client'

interface IFormSignIn {
  email: string
  password: string
}

const signIn = async ({ email, password }: IFormSignIn) => {
  const response = (
    await httpClient.post(`users/aws-cognito-login`, {
      email,
      password
    })
  ).data
  return response
}

export { signIn }
