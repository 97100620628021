import httpClient from 'http-client'
import PlanForCreate from 'models/for-create/plan'
import PlanForUpdate from 'models/for-update/plan'
import Plan from 'models/plan'
import Plans from 'models/plans'

import { Filters } from './contents'

export const getAllPlans = async (filters?: Filters): Promise<Plan> => {
  const allPlans = (
    await httpClient.get('plans?is_paginated=true', { params: filters })
  ).data

  return allPlans
}

export const getplanById = async (planId: string): Promise<Plans> => {
  const plans = (await httpClient.get(`plans/${planId}`)).data

  return plans
}

export const createPlan = async (newPlan: PlanForCreate): Promise<Plan> => {
  const createPlanResponse = (await httpClient.post(`plans`, newPlan)).data

  return createPlanResponse
}

export const updatePlan = async (
  planId: string,
  newPlan: PlanForUpdate
): Promise<void> => {
  const author = (await httpClient.patch(`plans/${planId}`, newPlan)).data

  return author
}

interface PlanRequest {
  title?: string
  is_active?: boolean
  id?: string
}

export const updatePlanById = async (
  planId: string,
  data: PlanRequest
): Promise<Plans> => {
  return (await httpClient.patch(`plans/${planId}`, data)).data
}

export const deletePlan = async (planId: string) => {
  await httpClient.delete(`plans/${planId}`)
}
