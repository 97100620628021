import { useMemo, useState } from 'react'
import { AiOutlineCheck, AiOutlineEdit } from 'react-icons/ai'
import { BiErrorAlt, BiTrash } from 'react-icons/bi'
import { Link, useHistory } from 'react-router-dom'

import Box from 'components/Box'
import BreadCrumb from 'components/BreadCrumb'
import Button from 'components/Button/Button/index'
import Checkbox from 'components/Checkbox'
import Form from 'components/Form'
import FormInputs from 'components/FormInputs'
import Input from 'components/Input'
import Label from 'components/Label'
import PageTitle from 'components/PageTitle'
import Table from 'components/Table'
import { hideModal, showModal } from 'helpers/modal'
import Plan from 'models/plan'

import contents from '../../mock/contents.json'
import { Container, FormButtons } from './styles'

export default function Trail() {
  const [plans, setPlans] = useState([] as Plan[])

  const [modalIsOpen, setModalIsOpen] = useState(true)

  const [selectAll, setSelectAll] = useState<boolean>(false)

  const history = useHistory()

  const createTrail = (): void => {
    history.push('create-trail')
  }

  const editTrail = (): void => {
    history.push('edit-trail/:trailId')
  }

  const Edit = () => {
    return (
      <>
        <Form>
          <FormInputs>
            <Label htmlFor="title" className="required">
              Título
            </Label>

            <Input id="title" />
          </FormInputs>

          <FormButtons>
            <Button type="button" styleButton="secondButton">
              Cancelar
            </Button>

            <Button
              className="success"
              onClick={(e) => {
                e.preventDefault()

                setModalIsOpen(false)

                showModal({
                  title: 'SE FUDDEEEEEEEEEEEEEEEEE',

                  content: <Edit2 />
                })
              }}
            >
              PRÓXIMO PASSO
            </Button>
          </FormButtons>
        </Form>
      </>
    )
  }

  const Edit2 = () => {
    return (
      <>
        <Form>
          <FormInputs>
            <Label htmlFor="title" className="required">
              Título
            </Label>

            <Input id="title" />
          </FormInputs>

          <FormInputs>
            <Label htmlFor="title" className="required">
              Título
            </Label>

            <Input id="title" />
          </FormInputs>

          <FormInputs>
            <Label htmlFor="title" className="required">
              Título
            </Label>

            <Input id="title" />
          </FormInputs>

          <FormButtons>
            <Button type="button" styleButton="secondButton">
              Cancelar
            </Button>

            <Button className="success">PRÓXIMO PASSO</Button>
          </FormButtons>
        </Form>
      </>
    )
  }

  const contentsToBeShown = useMemo(() => {
    return contents && contents.length
      ? contents.map((content) => ({
          selectAll: (
            <div
              style={{
                display: 'flex',

                gap: '5px'
              }}
            >
              <Checkbox />
            </div>
          ),

          id: content.content_id,

          title: content.title,

          active: (
            <div
              style={{
                display: 'flex',

                gap: '5px'
              }}
            >
              <AiOutlineCheck size={25} />
            </div>
          ),

          actions: (
            <div
              style={{
                display: 'flex',

                gap: '5px'
              }}
            >
              <Button
                className="small danger"
                title="Editar Usuário"
                styleButton="edit"
                onClick={editTrail}
              >
                <div>
                  <AiOutlineEdit className="icon-danger" />
                </div>
              </Button>

              <Button
                className="small danger"
                title="Atenção Usuário"
                styleButton="attencion"
              >
                <div>
                  <BiErrorAlt className="icon-danger" />
                </div>
              </Button>

              <Button
                className="small danger"
                title="Excluir Usuário"
                styleButton="danger"
              >
                <div>
                  <BiTrash className="icon-danger" />
                </div>
              </Button>
            </div>
          )
        }))
      : []
  }, [plans])

  return (
    <Container>
      <BreadCrumb
        crumbs={[
          <Link key={1} to="/home">
            Início
          </Link>,

          <span key={2}>Trilhas</span>
        ]}
      />

      <PageTitle>Trilhas</PageTitle>

      <Box padding="0 0 20px 0">
        <Button onClick={createTrail}>CRIAR TRILHA</Button>
      </Box>

      <Table
        headersConfig={[
          {
            headerLabel: <Checkbox />,

            propName: 'selectAll'
          },

          {
            headerLabel: <span>Título</span>,

            propName: 'title'
          },

          {
            headerLabel: <span>Ativo</span>,

            propName: 'active'
          },

          {
            headerLabel: <span>Ações</span>,

            propName: 'actions'
          }
        ]}
        itemsToShow={contentsToBeShown}
        emptyListMessage={'Não foram encontrados planos cadastradas!'}
      />
    </Container>
  )
}
