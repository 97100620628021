import styled from 'styled-components'

const CutImageContainer = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`

const SelectImageContainer = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ImageCutContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .buttons {
    margin: 25px 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    button:first-of-type {
      margin-right: 20px;
    }
  }
`

export { CutImageContainer, SelectImageContainer, ImageCutContainer }
