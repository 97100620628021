import React, { useCallback, useEffect, useState } from 'react'
import { AiOutlineDownload } from 'react-icons/ai'
import { Link, useHistory, useParams } from 'react-router-dom'

import BreadCrumb from 'components/BreadCrumb'
import Button from 'components/Button'
import CutImage from 'components/CutImage'
import Form from 'components/Form'
import FormInputs from 'components/FormInputs'
import Input from 'components/Input'
import Label from 'components/Label'
import PageTitle from 'components/PageTitle'
import UploadContainer from 'components/UploadContainer'
import { hideModal, showModal } from 'helpers/modal'
import { CourseThumbnail } from 'pages/Trail/CreateAndEditTrail/styles'
import {
  createCategory as createCategoryService,
  getCategory as getCategoryServices
} from 'services/categories'
import Swal from 'sweetalert2'

import { FormButtons } from './styles'
interface CreateAndEditCategoryProps {
  categoryId: string
}
export default function CreateAndEditCategory() {
  const [title, setTitle] = useState('')
  const [thumbnail, setThumbnail] = useState('')
  const [thumbnailFileToUpload, setThumbnailFileToUpload] = useState<File>()
  const { categoryId } = useParams<CreateAndEditCategoryProps>()

  const history = useHistory()

  const getCategory = useCallback(async () => {
    if (categoryId) {
      const category = await getCategoryServices(categoryId)
      if (category) {
        setTitle(category.title)
        setThumbnail(category.thumbnail)
      }
    }
  }, [categoryId])
  const onCutImage = (file: File) => {
    if (file) {
      setThumbnailFileToUpload(file)
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => setThumbnail(`${reader.result}`)

      hideModal()
    }
  }

  const selectImage = () => {
    showModal({
      title: 'Selecionar Imagem de Capa mobile',
      content: <CutImage onCutImage={onCutImage} />,
      styleModal: 'large'
    })
  }
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    try {
      const createCategory = {
        title: title,
        thumbnail: thumbnailFileToUpload
      }
      await createCategoryService(createCategory)

      Swal.fire({
        title: '',

        text: 'Categoria criada com sucesso!',

        icon: 'success'
      })

      history.push('/categories')
    } catch (error) {
      Swal.fire({
        title: 'Erro',
        text: 'Houve um erro ao buscar o material. ' + error,
        icon: 'error'
      })
    }
  }

  useEffect(() => {
    getCategory()
  }, [getCategory])
  return (
    <div>
      <BreadCrumb
        crumbs={[
          <Link key={1} to="/home">
            Início
          </Link>,

          <Link key={2} to="/categories">
            Categorias
          </Link>,
          <span key={3}>Categorias</span>
        ]}
      />

      <PageTitle>Categorias</PageTitle>

      <Form onSubmit={handleSubmit}>
        <FormInputs>
          <Label htmlFor="title" className="required">
            Título
          </Label>
          <Input
            id="title"
            required
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </FormInputs>

        <FormInputs>
          <UploadContainer text="Miniatura">
            <Button type="button" onClick={selectImage}>
              <AiOutlineDownload size={20} />
              SELECIONAR IMAGEM DE MINIATURA
            </Button>
            {thumbnail && <CourseThumbnail src={thumbnail} />}
          </UploadContainer>
        </FormInputs>

        <FormButtons>
          <Button type="button" styleButton="secondButton">
            Cancelar
          </Button>

          <Button className="success" type="submit">
            Salvar
          </Button>
        </FormButtons>
      </Form>
    </div>
  )
}
