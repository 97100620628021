import React, { useEffect, useRef, useState } from 'react'
import { AiOutlineCloudUpload } from 'react-icons/ai'
import { BsSearch } from 'react-icons/bs'

import Button from 'components/Button'
import SambaVideosContentCard from 'components/SambaVideosContentCard'
import MediaSambaVideos from 'models/media'
import SambaUploader from 'samba-uploader'
import { getMedias } from 'services/medias'
import { createContentData, getVideos, outrareq } from 'services/video'
import Swal from 'sweetalert2'

import UploadProgressBar from './components/UploadProgressBar'
import {
  ContentList,
  ContentListContainer,
  ContentUpload,
  Input,
  SelectSambaVideosContentContainer
} from './style'
interface SelectSambaVideosContentProps {
  setReference: any
}
const SelectSambaVideosContent = ({
  setReference
}: SelectSambaVideosContentProps) => {
  const [isUploading, setIsUploading] = useState(false)
  const [completeUpload, setCompleteUpload] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)
  const [contents, setContents] = useState([] as MediaSambaVideos[])
  const [shouldShowLoading, setShouldShowLoading] = useState(true)
  const [sambaUploader, setSambaUploader] = useState({} as any)

  const getSambaVideosContents = async (event?: React.FormEvent) => {
    if (event && event.preventDefault) {
      event.preventDefault()
    }
    setCompleteUpload(false)
    setContents([])
    const localContents = await getVideos()
    if (localContents && localContents.length) {
      setContents(localContents)
      setShouldShowLoading(true)
    } else {
      setShouldShowLoading(false)
    }
  }

  useEffect(
    () => {
      getSambaVideosContents()
    },
    // eslint-disable-next-line
    [completeUpload]
  )
  const handleContent = async (event: React.FormEvent<HTMLInputElement>) => {
    const file =
      event.currentTarget.files && event.currentTarget.files.length
        ? event.currentTarget.files[0]
        : null

    const contentData = await createContentData()
    await outrareq(contentData.uploadUrl, file)

    return contentData.uploadPath
  }
  const selectContent = async (event: any) => {
    setReference(event.id)
  }
  useEffect(() => {
    if (sambaUploader && Object.keys(sambaUploader).length) {
      sambaUploader.on('start', () => {
        Swal.fire({
          title: 'Iniciando Upload',
          text: 'Upload de conteúdo iniciado!',
          timer: 2000
        })
      })

      sambaUploader.on('progress', (event: any) => {
        const progress = (event.uploadedBytes / event.totalBytes) * 100
        if (progress) {
          setUploadProgress(progress)
          setIsUploading(true)
        }
      })

      sambaUploader.on('complete', async (event: any) => {
        Swal.fire({
          title: 'Sucesso!',
          text: 'Upload realizado com sucesso! Aguarde o tempo de encoding que pode durar até 10 min, após isso seu vídeo estará disponível para cadastro.',
          timer: 4000
        })

        setUploadProgress(0)
        setIsUploading(false)
        setCompleteUpload(true)
      })
    }
  }, [sambaUploader])

  return (
    <SelectSambaVideosContentContainer>
      <Input type="file" onClick={(event) => handleContent(event)} />
      <ContentUpload>
        <AiOutlineCloudUpload size={128} />
        {!isUploading && <p>Fazer Upload de Conteúdo</p>}
        {isUploading && <p>Aguarde o Upload do Conteúdo</p>}
        {uploadProgress > 0 ? (
          <UploadProgressBar progress={uploadProgress} />
        ) : (
          <></>
        )}
      </ContentUpload>

      <ContentListContainer>
        {/* <ContentListFilters onSubmit={getSambaVideosContents}>
          <ContentListFilter
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            type="search"
          />
          <Button type="submit">
            <BsSearch />
          </Button>
        </ContentListFilters> */}

        <ContentList>
          {contents && contents.length ? (
            <>
              {contents.map((content) => (
                <SambaVideosContentCard
                  onClick={(e) => selectContent(e)}
                  key={content.id}
                  content={content}
                />
              ))}
            </>
          ) : shouldShowLoading ? (
            <div className="spinner"></div>
          ) : (
            <>Nenhum conteúdo encontrado.</>
          )}
        </ContentList>
      </ContentListContainer>
    </SelectSambaVideosContentContainer>
  )
}

export default SelectSambaVideosContent
