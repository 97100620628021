import react, { useEffect, useState, useCallback, useMemo } from 'react'
import { AiOutlineCheck, AiOutlineClose, AiOutlineEdit } from 'react-icons/ai'
import { BiErrorAlt, BiTrash } from 'react-icons/bi'
import { Link, useHistory } from 'react-router-dom'

import Box from 'components/Box'
import BreadCrumb from 'components/BreadCrumb'
import Button from 'components/Button/Button/index'
import Checkbox from 'components/Checkbox'
import PageTitle from 'components/PageTitle'
import Table from 'components/Table'
import Authors from 'models/authors'
import { deleteAuthor, getAllAuthors, updateAuthorById } from 'services/authors'
import { Filters } from 'services/contents'
import Swal from 'sweetalert2'

import { Container } from './styles'

const itensPerPage = 12

export default function Author() {
  const [authors, setAuthors] = useState<Authors>()
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [actualPage, setActualPage] = useState<any>()

  const history = useHistory()

  const createAuthors = (): void => {
    history.push('create-author')
  }

  const editAuthors = useCallback(
    (authorId: string) => {
      history.push(`edit-author/${authorId}`)
    },
    [history]
  )

  const getAuthors = useCallback(
    async (filters: Filters) => {
      if (history.location.search) {
        const page = history.location.search.split('?page=')
        filters.page = parseInt(page[1])
      }

      if (filters.page === 0) {
        delete filters.page
      }
      const allAuthors = await getAllAuthors(filters)
      if (allAuthors.meta_data) {
        const pagination = allAuthors.meta_data
        setActualPage(pagination.page)
        setTotalPages(pagination.page_count)
      }
      if (allAuthors.data && allAuthors.data.length >= 0) {
        setAuthors(allAuthors)
      }
    },
    [history.location.search]
  )
  useEffect(() => {
    getAuthors({ take: itensPerPage, page: page })
  }, [getAuthors, page])

  const removeAuthor = useCallback(
    async (chargeId: string) => {
      Swal.fire({
        title: '<strong>Confirmação</strong>',

        html: 'Tem certeza que deseja remover este autor?',

        showCancelButton: true,

        cancelButtonText: 'Cancelar',

        focusConfirm: false
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await deleteAuthor(chargeId)

            Swal.fire({
              icon: 'success',

              title: 'Sucesso!',

              text: 'Autor excluído com sucesso!'
            })

            await getAuthors({})
          } catch (error) {
            Swal.fire({
              icon: 'error',

              title: 'Erro',

              text: `Erro ao excluir autor. ${
                error.response
                  ? 'Este autor já está associado a um conteúdo!'
                  : error.message
              }`
            })
          }
        }
      })
    },
    [getAuthors]
  )
  const inactivateAuthor = useCallback(
    async (authorId: string, is_active: boolean) => {
      Swal.fire({
        title: '<strong>Confirmação</strong>',
        icon: 'question',
        html: 'Tem certeza que deseja intativar este author?',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        focusConfirm: false
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await updateAuthorById(authorId, { is_active: is_active })

            Swal.fire({
              icon: 'success',
              title: 'Sucesso!',
              text: 'Author inativado com sucesso!'
            })
            await getAuthors({})
          } catch (e) {
            Swal.fire({
              icon: 'error',
              title: 'Erro ao inativar author',
              text: e.message
            })
          }
        }
      })
    },
    [getAuthors]
  )

  const authorsToBeShown = useMemo(() => {
    return authors
      ? authors.data.map((author) => ({
          selectAll: (
            <div
              style={{
                display: 'flex',

                gap: '5px'
              }}
            >
              <Checkbox />
            </div>
          ),

          id: author.id,

          author: author.title,

          active: (
            <div
              style={{
                display: 'flex',

                gap: '5px'
              }}
            >
              <AiOutlineCheck size={25} />
            </div>
          ),

          actions: (
            <div
              style={{
                display: 'flex',

                gap: '5px'
              }}
            >
              <Button
                className="small danger"
                title="Editar Usuário"
                styleButton="edit"
                onClick={() => editAuthors(author.id)}
              >
                <div>
                  <AiOutlineEdit className="icon-danger" />
                </div>
              </Button>

              <Button
                className="small danger"
                title="Inativar autor"
                styleButton="attencion"
                onClick={() => {
                  inactivateAuthor(author.id, !author.is_active)
                }}
              >
                <div>
                  <AiOutlineClose className="icon-danger" />
                </div>
              </Button>

              <Button
                className="small danger"
                title="Excluir Autor"
                styleButton="danger"
                onClick={() => removeAuthor(author.id)}
              >
                <div>
                  <BiTrash className="icon-danger" />
                </div>
              </Button>
            </div>
          )
        }))
      : []
  }, [editAuthors, authors, removeAuthor, inactivateAuthor])

  return (
    <Container>
      <BreadCrumb
        crumbs={[
          <Link key={1} to="/home">
            Início
          </Link>,

          <span key={2}>Autores</span>
        ]}
      />

      <PageTitle>Autores</PageTitle>

      <Box padding="0 0 20px 0">
        <Button onClick={createAuthors}>CRIAR AUTOR</Button>
      </Box>

      <Table
        headersConfig={[
          {
            headerLabel: <Checkbox />,

            propName: 'selectAll'
          },

          {
            headerLabel: <span>Autores</span>,

            propName: 'author'
          },

          {
            headerLabel: <span>Ativo</span>,

            propName: 'active'
          },

          {
            headerLabel: <span>Ações</span>,

            propName: 'actions'
          }
        ]}
        handleChangePage={(page) =>
          getAuthors({ take: itensPerPage, page: page })
        }
        actualPage={actualPage}
        totalPages={totalPages}
        setPage={(page) => setPage(page)}
        foot
        itemsToShow={authorsToBeShown}
        emptyListMessage={'Não foram encontrados autores cadastrados!'}
      />
    </Container>
  )
}
