import { useCallback, useEffect, useMemo, useState } from 'react'
import { AiOutlineCheck, AiOutlineClose, AiOutlineEdit } from 'react-icons/ai'
import { BiErrorAlt, BiTrash } from 'react-icons/bi'
import { Link, useHistory } from 'react-router-dom'

import Box from 'components/Box'
import BreadCrumb from 'components/BreadCrumb'
import Button from 'components/Button/Button/index'
import Checkbox from 'components/Checkbox'
import PageTitle from 'components/PageTitle'
import Table from 'components/Table'
import Category from 'models/categories'
import {
  getAllCategories as getAllCategoriesServices,
  deleteCategory as deleteCategoryServices,
  updateCategoryById
} from 'services/categories'
import { Filters } from 'services/contents'
import Swal from 'sweetalert2'

import { Container } from './styles'

const itensPerPage = 12

export default function Charges() {
  const [categorys, setCategory] = useState({} as Category)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [actualPage, setActualPage] = useState<any>()

  const history = useHistory()

  const createCategory = (): void => {
    history.push('create-category')
  }

  const editCategory = useCallback(
    (categoryId: string) => {
      history.push(`edit-category/${categoryId}`)
    },
    [history]
  )
  const getCategories = useCallback(
    async (filters: Filters) => {
      if (history.location.search) {
        const page = history.location.search.split('?page=')
        filters.page = parseInt(page[1])
      }

      if (filters.page === 0) {
        delete filters.page
      }
      const allCategories = await getAllCategoriesServices(filters)
      if (allCategories.meta_data) {
        const pagination = allCategories.meta_data
        setActualPage(pagination.page)
        setTotalPages(pagination.page_count)
      }
      if (allCategories.data && allCategories.data.length >= 0) {
        setCategory(allCategories)
      }
    },
    [history.location.search]
  )
  useEffect(() => {
    getCategories({ take: itensPerPage, page: page })
  }, [getCategories, page])

  const deleteCategory = useCallback(
    async (categoryId: string) => {
      Swal.fire({
        title: '<strong>Confirmação</strong>',
        icon: 'question',
        html: 'Tem certeza que deseja remover esta categoria?',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        focusConfirm: false
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await deleteCategoryServices(categoryId)

            Swal.fire({
              icon: 'success',
              title: 'Sucesso!',
              text: 'Curso excluido com sucesso!'
            })
            await getCategories({})
          } catch (e) {
            Swal.fire({
              icon: 'error',
              title: 'Erro ao excluir categoria',
              text: e.message
            })
          }
        }
      })
    },
    [getCategories]
  )

  const inactivateCategory = useCallback(
    async (categoryId: string, is_active: boolean) => {
      Swal.fire({
        title: '<strong>Confirmação</strong>',
        icon: 'question',
        html: 'Tem certeza que deseja intativar esta categoria?',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        focusConfirm: false
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await updateCategoryById(categoryId, { is_active: is_active })

            Swal.fire({
              icon: 'success',
              title: 'Sucesso!',
              text: 'Categoria inativada com sucesso!'
            })
            await getCategories({})
          } catch (e) {
            Swal.fire({
              icon: 'error',
              title: 'Erro ao inativar categoria',
              text: e.message
            })
          }
        }
      })
    },
    [getCategories]
  )

  const categoriesToBeShown = useMemo(() => {
    return categorys.data && categorys.data.length
      ? categorys.data.map((category) => ({
          selectAll: (
            <div
              style={{
                display: 'flex',

                gap: '5px'
              }}
            >
              <Checkbox />
            </div>
          ),

          id: category.id,

          title: category.title,

          active: (
            <div
              style={{
                display: 'flex',

                gap: '5px'
              }}
            >
              <AiOutlineCheck size={25} />
            </div>
          ),

          actions: (
            <div
              style={{
                display: 'flex',

                gap: '5px'
              }}
            >
              <Button
                className="small danger"
                title="Editar Usuário"
                styleButton="edit"
                onClick={() => editCategory(category.id)}
              >
                <div>
                  <AiOutlineEdit className="icon-danger" />
                </div>
              </Button>

              <Button
                className="small danger"
                title={
                  category.is_active ? 'Inativar' : 'Ativar' + ' Categoria'
                }
                styleButton="attencion"
                onClick={() => {
                  inactivateCategory(category.id, !category.is_active)
                }}
              >
                <div>
                  <AiOutlineClose className="icon-danger" />
                </div>
              </Button>

              <Button
                className="small danger"
                title="Excluir categoria"
                styleButton="danger"
                onClick={() => deleteCategory(category.id)}
              >
                <div>
                  <BiTrash className="icon-danger" />
                </div>
              </Button>
            </div>
          )
        }))
      : []
  }, [categorys, deleteCategory, editCategory])

  return (
    <Container>
      <BreadCrumb
        crumbs={[
          <Link key={1} to="/home">
            Início
          </Link>,

          <span key={2}>Categorias</span>
        ]}
      />

      <PageTitle>Categorias</PageTitle>

      <Box padding="0 0 20px 0">
        <Button onClick={createCategory}>CRIAR NOVA CATEGORIA</Button>
      </Box>

      <Table
        headersConfig={[
          {
            headerLabel: <Checkbox />,

            propName: 'selectAll'
          },

          {
            headerLabel: <span>Título</span>,

            propName: 'title'
          },

          {
            headerLabel: <span>Ativo</span>,

            propName: 'active'
          },

          {
            headerLabel: <span>Ações</span>,

            propName: 'actions'
          }
        ]}
        handleChangePage={(page) =>
          getCategories({ take: itensPerPage, page: page })
        }
        actualPage={actualPage}
        totalPages={totalPages}
        setPage={(page) => setPage(page)}
        foot
        itemsToShow={categoriesToBeShown}
        emptyListMessage={'Não foram encontradas categorias cadastradas!'}
      />
    </Container>
  )
}
