import styled, { css } from 'styled-components'

export const Label = styled.p`
  font-size: var(--font-small);
  font-weight: 400;
  margin-bottom: 10px;
`

export const UserName = styled.p`
  font-size: var(--font-medium);
  font-weight: 600;
  color: var(--white);
`

export const Buttons = styled.div`
  margin-top: auto;
  margin-left: auto;
  > div {
    > button {
      padding: 15px 20px;
    }
  }
`

export const Date = styled.div`
  input {
    height: 45px;
    font-size: var(--font-small);
    padding: 0 15px;
  }
`

export const CardContainer = styled.div`
  display: flex;
  gap: 2.5rem;
  margin: 3rem 0;
`

export const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const UserButton = styled.button`
  background: green;
  color: var(--white);
  padding: 15px !important;
  font-size: var(--font-small);
  border-radius: 30px;
  font-weight: bold;
  border: none;
  cursor: pointer;
`

export const Card = styled.div`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.white};
    border-radius: ${theme.borderRadius};
    font-weight: ${theme.font.bold};
  `}

  margin-top: 3.7rem;
  margin-bottom: 2.6rem;
  max-width: 21.6rem;
  padding: 1.6rem;
  width: 100%;

  h2 {
    font-size: 1.6rem;
    line-height: 2.7rem;
    text-transform: uppercase;
    margin-bottom: 1.6rem;
  }

  span {
    font-size: 3.2rem;
    line-height: 2.4rem;
  }
`

export const SearchContainer = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    border-bottom: solid 1px ${theme.colors.gray};
    justify-content: space-between;
    height: 100%;
    margin-bottom: 3.2rem;
    padding-bottom: 1.6rem;
  `}
`

export const SearchInput = styled.form`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.gray};
    border-radius: ${theme.borderRadius};
    height: 4.8rem;
    position: relative;
    width: 33.2rem;

    & input {
      background-color: transparent;
      border: none;
      color: ${theme.colors.gray};
      font-size: 1.6rem;
      height: 100%;
      outline: none;
      padding: 1.1rem 5.4rem 1.1rem 1.6rem;
      width: 100%;
    }

    & button {
      background-color: transparent;
      border: none;
      color: ${theme.colors.gray};
      height: 100%;
      outline: none;
      position: absolute;
      right: 1.8rem;
      top: 0;
    }

    &:hover {
      border-color: ${theme.colors.primary};
    }
  `}
`

export const Title = styled.h2`
  ${({ theme }) => css`
    font-size: 2.4rem;
    font-weight: ${theme.font.bold};
    line-height: 3.2rem;
  `}
`
