import { useCallback, useEffect, useMemo, useState } from 'react'
import { AiOutlineCheck, AiOutlineClose, AiOutlineEdit } from 'react-icons/ai'
import { BiErrorAlt, BiTrash } from 'react-icons/bi'
import { Link, useHistory } from 'react-router-dom'

import Box from 'components/Box'
import BreadCrumb from 'components/BreadCrumb'
import Button from 'components/Button/Button/index'
import Checkbox from 'components/Checkbox'
import PageTitle from 'components/PageTitle'
import Table from 'components/Table'
import ContentsModel from 'models/contents'
import Plan from 'models/plan'
import {
  Filters,
  getAllContents,
  deleteContent,
  updateContentById
} from 'services/contents'
import Swal from 'sweetalert2'

import { Container } from './style'

const itensPerPage = 12

export default function Contents() {
  const [contents, setContents] = useState({} as ContentsModel)
  const history = useHistory()
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [actualPage, setActualPage] = useState<any>()

  const getContent = useCallback(
    async (filters: Filters) => {
      if (history.location.search) {
        const page = history.location.search.split('?page=')
        filters.page = parseInt(page[1])
      }

      if (filters.page === 0) {
        delete filters.page
      }
      const allUsers = await getAllContents(filters)
      if (allUsers.meta_data) {
        const pagination = allUsers.meta_data
        setActualPage(pagination.page)
        setTotalPages(pagination.page_count)
      }
      if (allUsers.data && allUsers.data.length >= 0) {
        setContents(allUsers)
      }
    },
    [history.location.search]
  )
  const createContent = (): void => {
    history.push('create-content')
  }

  const editContent = useCallback(
    (contentId: string) => {
      history.push(`edit-content/${contentId}`)
    },
    [history]
  )
  const removeContent = useCallback(async (contentId: string) => {
    Swal.fire({
      title: '<strong>Confirmação</strong>',

      html: 'Tem certeza que deseja remover este conteúdo?',

      showCancelButton: true,

      cancelButtonText: 'Cancelar',

      focusConfirm: false
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteContent(contentId)

          Swal.fire({
            icon: 'success',

            title: 'Sucesso!',

            text: 'Conteúdo excluído com sucesso!'
          })

          await getContent({})
        } catch (error) {
          Swal.fire({
            icon: 'error',

            title: 'Erro',

            text: `Erro ao excluir conteúdo. ${
              error.response
                ? 'Esta conteúdo já está associado!'
                : error.message
            }`
          })
        }
      }
    })
  }, [])

  const inactivateContent = useCallback(
    async (contentId: string, is_active: boolean) => {
      Swal.fire({
        title: '<strong>Confirmação</strong>',
        icon: 'question',
        html: `Tem certeza que deseja ${
          !is_active ? 'ativar' : 'inativar'
        } este conteúdo?`,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        focusConfirm: false
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await updateContentById(contentId, { is_active: is_active })

            Swal.fire({
              icon: 'success',
              title: 'Sucesso!',
              text: `Conteúdo ${
                !is_active ? 'ativado com sucesso!' : 'inativado com sucesso!'
              }`
            })
            await getContent({})
          } catch (e) {
            Swal.fire({
              icon: 'error',
              title: 'Erro ao inativar o conteúdo',
              text: e.message
            })
          }
        }
      })
    },
    [getContent]
  )

  const contentsToBeShown = useMemo(() => {
    return contents.data && contents.data.length
      ? contents.data.map((content) => ({
          selectAll: (
            <div
              style={{
                display: 'flex',
                gap: '5px'
              }}
            >
              <Checkbox />
            </div>
          ),

          id: content.id,
          title: content.title,
          description: content.description,
          active: (
            <div
              style={{
                display: 'flex',
                gap: '5px'
              }}
            >
              {content.is_active ? (
                <>
                  <AiOutlineClose size={25} />{' '}
                </>
              ) : (
                <>
                  <AiOutlineCheck size={25} />
                </>
              )}
            </div>
          ),
          actions: (
            <div
              style={{
                display: 'flex',
                gap: '5px'
              }}
            >
              <Button
                className="small danger"
                title="Editar conteúdp"
                styleButton="edit"
                onClick={() => editContent(content.id)}
              >
                <div>
                  <AiOutlineEdit className="icon-danger" />
                </div>
              </Button>
              <Button
                className="small danger"
                title={content.is_active ? 'Inativar' : 'Ativar' + ' conteúdo'}
                styleButton="attencion"
                onClick={() => {
                  inactivateContent(content.id, !content.is_active)
                }}
              >
                <div>
                  <AiOutlineClose className="icon-danger" />
                </div>
              </Button>
              <Button
                className="small danger"
                title="Excluir conteúdo"
                styleButton="danger"
                onClick={() => removeContent(content.id)}
              >
                <div>
                  <BiTrash className="icon-danger" />
                </div>
              </Button>
            </div>
          )
        }))
      : []
  }, [contents.data, editContent, removeContent, inactivateContent])

  useEffect(() => {
    getContent({ take: itensPerPage, page: page })
  }, [getContent, page])
  return (
    <Container>
      <BreadCrumb
        crumbs={[
          <Link key={1} to="/home">
            Início
          </Link>,
          <span key={2}>Conteúdos</span>
        ]}
      />

      <PageTitle>Conteúdos</PageTitle>

      <Box padding="0 0 20px 0">
        <Button onClick={createContent}>Criar Conteúdo</Button>
      </Box>

      <Table
        headersConfig={[
          {
            headerLabel: <Checkbox />,
            propName: 'selectAll'
          },
          {
            headerLabel: <span>Título</span>,
            propName: 'title'
          },
          {
            headerLabel: <span>Descrição</span>,
            propName: 'description'
          },
          {
            headerLabel: <span>Ativo</span>,
            propName: 'active'
          },
          {
            headerLabel: <span>Ações</span>,
            propName: 'actions'
          }
        ]}
        handleChangePage={(page) =>
          getContent({ take: itensPerPage, page: page })
        }
        actualPage={actualPage}
        totalPages={totalPages}
        itemsToShow={contentsToBeShown}
        setPage={(page) => setPage(page)}
        foot
        emptyListMessage={'Não foram encontrados conteúdos cadastrados!'}
      />
    </Container>
  )
}
