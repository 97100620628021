import Author from 'models/author'
import Authors from 'models/authors'
import AuthorForCreate from 'models/for-create/author'

import httpClient from '../http-client'
import { Filters } from './contents'
interface AuthorCreateUpdate {
  title: string
}

export const getAuthor = async (authorId: string): Promise<Author> => {
  const autor = await (await httpClient.get(`authors/${authorId}`)).data

  return autor
}

const getAllAuthors = async (filters?: Filters): Promise<Authors> => {
  const authors = (
    await httpClient.get('authors?is_paginated=true', { params: filters })
  ).data

  return authors
}

const createAuthor = async (newAuthor: AuthorForCreate) => {
  const createAuthorResponse = (
    await httpClient.post<any>(`authors`, newAuthor)
  ).data

  return createAuthorResponse
}

const updateAuthor = async (
  authorId: string,
  authorToBeUpdated: AuthorCreateUpdate
): Promise<Author> => {
  const author = (
    await httpClient.patch(`authors/${authorId}`, authorToBeUpdated)
  ).data

  return author
}
interface UserRequest {
  title?: string
  is_active?: boolean
  id?: string
}

export const updateAuthorById = async (
  authorId: string,
  data: UserRequest
): Promise<Authors> => {
  return (await httpClient.patch(`authors/${authorId}`, data)).data
}

const deleteAuthor = async (authorId: string) => {
  await httpClient.delete(`authors/${authorId}`)
}

export { getAllAuthors, createAuthor, updateAuthor, deleteAuthor }
