import React, {
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import { CSVLink } from 'react-csv'
import {
  AiOutlineCheck,
  AiOutlineClose,
  AiOutlineEdit,
  AiOutlineSearch
} from 'react-icons/ai'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'

import BreadCrumb from 'components/BreadCrumb'
import Checkbox from 'components/Checkbox'
import Table from 'components/Table'
import User from 'models/user'
import Users from 'models/users'
import moment from 'moment'
import {
  deleteUserById,
  Filters,
  getUsers as getUsersService,
  updateUserById
} from 'services/users'
import Swal from 'sweetalert2'

import { ReactComponent as IconCsv } from '../../assets/csv.svg'
import { ReactComponent as IconDelete } from '../../assets/delete.svg'
import { ReactComponent as IconImport } from '../../assets/import.svg'
import Button from '../../components/Button/Button/index'
import {
  Card,
  Actions,
  Label,
  SearchContainer,
  SearchInput,
  Title
} from './style'

const itensPerPage = 12

const UsersPage: React.FC = () => {
  const [users, setUsers] = useState({} as Users)
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [searchInput, setSearchInput] = useState('')
  const [searchRequest, setSearchRequest] = useState('')
  const [allUsers, setAllUsers] = useState([] as User[])
  const [actualPage, setActualPage] = useState<any>()
  const [totalPages, setTotalPages] = useState(0)
  const history = useHistory()

  const headers = [
    { label: 'Nome', key: 'name' },
    { label: 'E-mail', key: 'email' },
    { label: 'Plano', key: 'planStr' }
  ]
  const editUser = useCallback(
    (UserId: string) => {
      history.push(`edit-user/${UserId}`)
    },
    [history]
  )
  const createUser = (): void => {
    history.push('create-user')
  }

  const getUsers = useCallback(
    async (filters: Filters) => {
      if (history.location.search) {
        const page = history.location.search.split('?page=')
        filters.page = parseInt(page[1])
      }
      if (searchRequest) {
        filters.search = searchRequest
      }

      if (filters.page === 0) {
        delete filters.page
      }
      const allUsers = await getUsersService(filters)
      if (allUsers.meta_data) {
        const pagination = allUsers.meta_data
        setActualPage(pagination.page)
        setTotalPages(pagination.page_count)
      }
      if (allUsers.data && allUsers.data.length >= 0) {
        setUsers(allUsers)
        setTotal(allUsers.meta_data.item_count)
      }
    },
    [history.location.search, searchRequest]
  )
  const inactivateUser = useCallback(
    async (userId: string, is_active: boolean) => {
      Swal.fire({
        title: '<strong>Confirmação</strong>',
        icon: 'question',
        html: 'Tem certeza que deseja intativar este usuário?',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        focusConfirm: false
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await updateUserById(userId, { is_active: is_active })

            Swal.fire({
              icon: 'success',
              title: 'Sucesso!',
              text: 'Usuário inativado com sucesso!'
            })
            await getUsers({})
          } catch (e) {
            Swal.fire({
              icon: 'error',
              title: 'Erro ao inativar usuário',
              text: e.message
            })
          }
        }
      })
    },
    [getUsers]
  )
  const deleteUser = useCallback(
    async (categoryId: string) => {
      Swal.fire({
        title: '<strong>Confirmação</strong>',
        icon: 'question',
        html: 'Tem certeza que deseja remover este usuário?',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        focusConfirm: false
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await deleteUserById(categoryId)

            Swal.fire({
              icon: 'success',
              title: 'Sucesso!',
              text: 'Usuário excluido com sucesso!'
            })
            await getUsers({})
          } catch (e) {
            Swal.fire({
              icon: 'error',
              title: 'Erro ao excluir usuário',
              text: e.message
            })
          }
        }
      })
    },
    [getUsers]
  )

  const usersToBeShown = useMemo(() => {
    return users && users.data
      ? users.data.map((user) => ({
          selectAll: (
            <div
              style={{
                display: 'flex',
                gap: '5px'
              }}
            >
              <Checkbox />
            </div>
          ),
          name: user.name,
          email: user.email,
          plan: user.plan === null ? 'usuário não tem plano' : user.plan,

          isAdmin: (
            <div
              style={{
                width: '100%',
                display: 'flex',
                gap: '5px'
              }}
            >
              {user.role === 'admin' ? (
                <AiOutlineCheck size={25} />
              ) : (
                <AiOutlineClose size={25} />
              )}
            </div>
          ),
          expiresIn: (
            <div
              style={{
                width: '100%',
                display: 'flex',
                gap: '5px'
              }}
            >
              <Label>
                {user.payment
                  ? moment(user?.payment[0]?.finish_at).format('DD/MM/YYYY')
                  : 'Sem data definida'}
              </Label>
            </div>
          ),
          actions: (
            <div
              style={{
                width: '100%',
                display: 'flex',
                gap: '5px'
              }}
            >
              <Button
                onClick={() => {
                  editUser(user.id)
                }}
                className="small danger"
                title="Editar Usuário"
                styleButton="edit"
              >
                <div style={{ display: 'flex' }}>
                  <AiOutlineEdit className="icon-danger" />
                </div>
              </Button>
              <Button
                onClick={() => {
                  inactivateUser(user.id, !user.is_active)
                }}
                className="small danger"
                title="Desativar Usuário"
                styleButton="attencion"
              >
                <div style={{ display: 'flex' }}>
                  <AiOutlineClose className="icon-danger" />
                </div>
              </Button>
              <Button
                onClick={() => {
                  deleteUser(user.id)
                }}
                className="small danger"
                title="Excluir Usuário"
                styleButton="danger"
              >
                <div style={{ display: 'flex' }}>
                  <IconDelete className="icon-danger" />
                </div>
              </Button>
            </div>
          ),
          status: (
            <div
              style={{
                width: '100%',
                display: 'flex',
                gap: '5px'
              }}
            >
              {user.is_active && user.term_accepted && 'Ativo'}
              {user.is_active && !user.term_accepted && 'Primeiro login'}
              {user.deleted_at && 'Deletado'}
              {!user.is_active && 'Inativo'}
            </div>
          )
        }))
      : []
  }, [deleteUser, editUser, inactivateUser, users])

  useEffect(() => {
    getUsers({ take: itensPerPage, page: page })
  }, [getUsers, page, searchRequest])

  const handleGetUserSubmit = (e: FormEvent): void => {
    e.preventDefault()
    setSearchRequest(searchInput)

    if (page === 1) {
      getUsers({ take: itensPerPage, page: page })
    } else {
      setPage(1)
    }
  }

  return (
    <div>
      <BreadCrumb
        crumbs={[
          <Link key={1} to="/home">
            Início
          </Link>,
          <span key={2}>Usuários</span>
        ]}
      />

      <SearchContainer>
        <Title>Usuários</Title>
        <SearchInput onSubmit={handleGetUserSubmit}>
          <input
            type="search"
            placeholder="Nome ou e-mail"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
          <button type="submit">
            <AiOutlineSearch size={24} />
          </button>
        </SearchInput>
      </SearchContainer>

      <Actions>
        <Button onClick={createUser}>
          <span>Criar usuário</span>
        </Button>

        {allUsers.length ? (
          <Button>
            <CSVLink
              filename={`sgda-todos-usuarios.csv`}
              data={allUsers}
              headers={headers}
              className="csv-download-button"
            >
              <IconCsv />
              <span>exportar todos usuários</span>
            </CSVLink>
          </Button>
        ) : (
          <Button>
            <IconCsv />
            <span>EXPORTAR SELEÇÃO ATUAL</span>
          </Button>
        )}

        <Button>
          <CSVLink
            filename={`sgda-usuarios-pagina-${page}.csv`}
            data={allUsers}
            headers={headers}
            className="csv-download-button"
          >
            <IconImport />
            <span>EXPORTAR TODOS OS USUÁRIOS</span>
          </CSVLink>
        </Button>
      </Actions>

      <Card>
        <h2>Total de Usuários</h2>
        <span>{total}</span>
      </Card>

      <Table
        headersConfig={[
          {
            headerLabel: <Checkbox />,
            propName: 'selectAll'
          },
          {
            headerLabel: <span>Nome</span>,
            propName: 'name'
          },
          {
            headerLabel: <span>E-mail</span>,
            propName: 'email'
          },
          {
            headerLabel: <span>Plano</span>,
            propName: 'plan'
          },
          {
            headerLabel: <span>Data de Expiração</span>,
            propName: 'expiresIn'
          },

          {
            headerLabel: <span>Admin</span>,
            propName: 'isAdmin'
          },
          {
            headerLabel: <span>Status</span>,
            propName: 'status'
          },
          {
            headerLabel: <span>Ações</span>,
            propName: 'actions'
          }
        ]}
        handleChangePage={(page) =>
          getUsers({ take: itensPerPage, page: page })
        }
        actualPage={actualPage}
        totalPages={totalPages}
        itemsToShow={usersToBeShown}
        setPage={(page) => setPage(page)}
        foot
        emptyListMessage={'Não foram encontrados usuários!'}
      />
    </div>
  )
}

export default UsersPage
