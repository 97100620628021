import axios from 'axios'
import httpClient from 'http-client'

interface createPhotoProps {
  image_name: string
  folder_name: string
}
export const createPhoto = async (data: createPhotoProps) => {
  const createPhotoResponse = (await httpClient.post(`aws-s3`, data)).data

  return createPhotoResponse
}

export const sendPhoto = async (uploadUrl, data) => {
  const createContentDataResponse = await axios.put(uploadUrl, data)
  return createContentDataResponse
}
