import { useState } from 'react'
import {
  AiOutlineArrowRight,
  AiOutlineDown,
  AiOutlineDownload,
  AiOutlineUp
} from 'react-icons/ai'
import { BsPlusLg } from 'react-icons/bs'
import { FaUnlink } from 'react-icons/fa'
import { TiEdit } from 'react-icons/ti'
import { Link } from 'react-router-dom'

import BreadCrumb from 'components/BreadCrumb'
import Button from 'components/Button'
import ButtonIcon from 'components/Button/Button'
import CutImage from 'components/CutImage'
import Form from 'components/Form'
import FormGroup from 'components/FormInputs'
import Input from 'components/Input'
import Label from 'components/Label'
import PageTitle from 'components/PageTitle'
import InputSelect from 'components/Select'
import TextArea from 'components/TextArea'
import ToggleSwitch from 'components/ToggleSwitch'
import UploadContainer from 'components/UploadContainer'
import { hideModal, showModal } from 'helpers/modal'

import {
  FormButtons,
  Dropdown,
  LessonSelectionContainer,
  SortLessonsContainer,
  DefaultButton,
  CourseThumbnail,
  ImageContainer
} from './styles'

const options = [
  { label: 'one', value: '1', style: { color: 'red' } },
  { label: 'two', value: '2', style: { color: 'blue' } }
]

const selectedLessons = [
  {
    id: 111,
    title: 'Title',
    description: 'Description',
    author: 'Author',
    categories: ['categories'],
    content: [
      {
        id: 'asuidhsad',
        title: 'asuidhsad',
        description: 'asuidhsad',
        duration: 111,
        active: true,
        reference: 'sei la',
        thumbnail: 'sei la',
        type: 'sei la',
        info: {
          scorm_version: 'oxeeeeeeeeeeeeeeeee'
        }
      }
    ],
    active: true,
    thumbnail:
      'http://t1.gstatic.com/images?q=tbn:ANd9GcQV8uVkp2AEazXrpeRNQngQTBEOME6OXR6AX0ITGYkSYNzz2_g3'
  },
  {
    id: 222,
    title: 'Title2',
    description: 'Description2',
    author: 'Author',
    categories: ['categories'],
    content: [
      {
        id: 'asuidhsad',
        title: 'asuidhsad',
        description: 'asuidhsad',
        duration: 111,
        active: true,
        reference: 'sei la',
        thumbnail: 'sei la',
        type: 'sei la',
        info: {
          scorm_version: 'oxeeeeeeeeeeeeeeeee'
        }
      }
    ],
    active: true,
    thumbnail:
      'http://t1.gstatic.com/images?q=tbn:ANd9GcQV8uVkp2AEazXrpeRNQngQTBEOME6OXR6AX0ITGYkSYNzz2_g3'
  },
  {
    id: 333,
    title: 'Title3',
    description: 'Description3',
    author: 'Author',
    categories: ['categories'],
    content: [
      {
        id: 'asuidhsad',
        title: 'asuidhsad',
        description: 'asuidhsad',
        duration: 111,
        active: true,
        reference: 'sei la',
        thumbnail: 'sei la',
        type: 'sei la',
        info: {
          scorm_version: 'oxeeeeeeeeeeeeeeeee'
        }
      }
    ],
    active: true,
    thumbnail:
      'http://t1.gstatic.com/images?q=tbn:ANd9GcQV8uVkp2AEazXrpeRNQngQTBEOME6OXR6AX0ITGYkSYNzz2_g3'
  },
  {
    id: 444,
    title: 'Title4',
    description: 'Description4',
    author: 'Author',
    categories: ['categories'],
    content: [
      {
        id: 'asuidhsad',
        title: 'asuidhsad',
        description: 'asuidhsad',
        duration: 111,
        active: true,
        reference: 'sei la',
        thumbnail: 'sei la',
        type: 'sei la',
        info: {
          scorm_version: 'oxeeeeeeeeeeeeeeeee'
        }
      }
    ],
    active: true,
    thumbnail:
      'http://t1.gstatic.com/images?q=tbn:ANd9GcQV8uVkp2AEazXrpeRNQngQTBEOME6OXR6AX0ITGYkSYNzz2_g3'
  }
]

export default function CreateAndEditTrail() {
  const [modalIsOpen, setModalIsOpen] = useState(true)
  const [thumbnailMobileFileToUpload, setThumbnailMobileFileToUpload] =
    useState<File>()
  const [thumbnailMobile, setThumbnailMobile] = useState('')
  const [toggleState, setToggleState] = useState(false)

  const AddModule = () => {
    return (
      <>
        <Form>
          <FormGroup>
            <Label htmlFor="title" className="required">
              Título
            </Label>
            <Input id="title" />
          </FormGroup>

          <FormGroup>
            <Label htmlFor="title">Conteúdos</Label>

            <Button
              type="button"
              styleButton="transparentIconButton"
              onClick={editCategory}
            >
              <BsPlusLg />
              adicionar um conteúdo
            </Button>
          </FormGroup>

          <FormButtons>
            <Button type="button" styleButton="secondButton">
              Cancelar
            </Button>
            <Button
              className="success"
              onClick={(e) => {
                e.preventDefault()
                setModalIsOpen(false)
                showModal({
                  title: 'Adicionar conteúdo',
                  content: <AddContent />
                })
              }}
            >
              PRÓXIMO PASSO
            </Button>
          </FormButtons>
        </Form>
      </>
    )
  }

  const AddContent = () => {
    return (
      <>
        <Form>
          <FormGroup>
            <Label htmlFor="title" className="required">
              Título
            </Label>
            <Input id="title" />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="description">Descrição</Label>
            <TextArea id="description" />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="thumbnail" className="required">
              Conteúdo
            </Label>
            <Button type="button" onClick={editCategory}>
              selecionar conteúdo
            </Button>
          </FormGroup>
          <FormButtons>
            <Button type="button" styleButton="secondButton">
              Cancelar
            </Button>
            <Button
              styleButton="iconButton"
              onClick={(e) => {
                e.preventDefault()
                setModalIsOpen(false)
                showModal({
                  title: 'Conteúdo',
                  content: <Module />
                })
              }}
            >
              AVANÇAR
              <AiOutlineArrowRight size={30} />
            </Button>
          </FormButtons>
        </Form>
      </>
    )
  }

  const Module = () => {
    return (
      <>
        <Form>
          <FormGroup>
            <Label htmlFor="title" className="required">
              Título
            </Label>
            <Input id="title" />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="description">Descrição</Label>
            <TextArea id="description" />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="content">Conteúdos</Label>
            <LessonSelectionContainer>
              {selectedLessons && selectedLessons.length ? (
                <SortLessonsContainer>
                  {selectedLessons.map((lesson, index) => (
                    <div key={lesson.id} className="selected-lessons">
                      <div className="buttons">
                        {index > 0 ? (
                          <DefaultButton
                            type="button"
                            title="Subir Aula"
                            className="small white up"
                          >
                            <AiOutlineUp size={20} />
                          </DefaultButton>
                        ) : (
                          <></>
                        )}
                        {index < selectedLessons.length - 1 ? (
                          <DefaultButton
                            type="button"
                            title="Descer Aula"
                            className="small white down"
                          >
                            <AiOutlineDown />
                          </DefaultButton>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="lesson-title">
                        {index + 1} - {lesson.title}
                      </div>
                      <ButtonIcon
                        className="small danger"
                        title="Editar Usuário"
                        styleButton="edit"
                        onClick={editCategory}
                      >
                        <div>
                          <TiEdit size={14} className="icon-danger" />
                        </div>
                      </ButtonIcon>
                      <Button styleButton="unlinkButton">
                        <FaUnlink size={15} />
                        Desvincular
                      </Button>
                    </div>
                  ))}
                </SortLessonsContainer>
              ) : (
                <></>
              )}
            </LessonSelectionContainer>
          </FormGroup>

          <FormGroup>
            <Button
              type="button"
              styleButton="transparentIconButton"
              onClick={editCategory}
            >
              <BsPlusLg />
              adicionar mais um conteúdo
            </Button>
          </FormGroup>
          <FormButtons>
            <Button type="button" styleButton="secondButton">
              Cancelar
            </Button>
            <Button className="success">CRIAR MÓDULO</Button>
          </FormButtons>
        </Form>
      </>
    )
  }

  const editCategory = (): void => {
    modalIsOpen
      ? showModal({ title: 'Adicionar módulo', content: <AddModule /> })
      : hideModal()
  }

  const onCutImage = (file: File) => {
    if (file) {
      setThumbnailMobileFileToUpload(file)

      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => setThumbnailMobile(`${reader.result}`)

      hideModal()
    }
  }

  const selectImage = () => {
    showModal({
      title: 'Selecionar Imagem de Capa mobile',
      content: <CutImage aspect={0.7} onCutImage={onCutImage} />,
      styleModal: 'large'
    })
  }

  return (
    <div>
      <BreadCrumb
        crumbs={[
          <Link key={1} to="/home">
            Início
          </Link>,

          <Link key={2} to="/categories">
            Trilhas
          </Link>,
          <span key={3}>Criar curso</span>
        ]}
      />

      <PageTitle>Criar curso</PageTitle>

      <Form>
        <FormGroup>
          <Label htmlFor="title" className="required">
            Título
          </Label>
          <Input id="title" required />
        </FormGroup>

        <FormGroup>
          <Label htmlFor="thumbnail" className="required">
            Adicionar Coleções
          </Label>
          <Button type="button" onClick={editCategory}>
            ADICIONAR COLEÇÕES
          </Button>
        </FormGroup>

        <InputSelect title="Categorias" options={options} />

        <ToggleSwitch
          label="Destacar conteúdo na Home"
          setToggleState={setToggleState}
        />

        <FormGroup>
          <ImageContainer>
            <UploadContainer text="Miniatura">
              <Button type="button" onClick={selectImage}>
                <AiOutlineDownload size={20} />
                SELECIONAR IMAGEM DE MINIATURA
              </Button>
              {thumbnailMobile && <CourseThumbnail src={thumbnailMobile} />}
            </UploadContainer>
            {toggleState && (
              <UploadContainer text="Miniatura para Destaque">
                <Button type="button" onClick={selectImage}>
                  <AiOutlineDownload size={20} />
                  SELECIONAR IMAGEM DE MINIATURA
                </Button>
                {thumbnailMobile && <CourseThumbnail src={thumbnailMobile} />}
              </UploadContainer>
            )}
          </ImageContainer>
        </FormGroup>

        <FormButtons>
          <Button type="button" styleButton="secondButton">
            Cancelar
          </Button>

          <Button className="success">Salvar</Button>
        </FormButtons>
      </Form>
    </div>
  )
}
