import { useMemo, useState, useEffect, useCallback } from 'react'
import { AiOutlineCheck, AiOutlineClose, AiOutlineEdit } from 'react-icons/ai'
import { BiErrorAlt, BiTrash } from 'react-icons/bi'
import { Link, useHistory } from 'react-router-dom'

import Box from 'components/Box'
import BreadCrumb from 'components/BreadCrumb'
import Button from 'components/Button/Button/index'
import Checkbox from 'components/Checkbox'
import PageTitle from 'components/PageTitle'
import Table from 'components/Table'
import Plan from 'models/plan'
import { Filters } from 'services/contents'
import { deletePlan, getAllPlans, updatePlanById } from 'services/plans'
import Swal from 'sweetalert2'

import { Container } from './styles'

const itensPerPage = 10

export default function Trail() {
  const [plans, setPlans] = useState<Plan>()
  const [selectAll, setSelectAll] = useState<boolean>(false)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [actualPage, setActualPage] = useState<any>()

  const history = useHistory()

  const createPlan = (): void => {
    history.push('create-plan')
  }
  const editPlan = useCallback(
    (planId: string) => {
      history.push(`edit-plan/${planId}`)
    },
    [history]
  )

  const getPlans = useCallback(
    async (filters: Filters) => {
      if (history.location.search) {
        const page = history.location.search.split('?page=')
        filters.page = parseInt(page[1])
      }

      if (filters.page === 0) {
        delete filters.page
      }
      const allPlans = await getAllPlans(filters)
      if (allPlans.meta_data) {
        const pagination = allPlans.meta_data
        setActualPage(pagination.page)
        setTotalPages(pagination.page_count)
      }
      if (allPlans.data && allPlans.data.length >= 0) {
        setPlans(allPlans)
      }
    },
    [history.location.search]
  )
  useEffect(() => {
    getPlans({ take: itensPerPage, page: page })
  }, [getPlans, page])

  const removePlan = useCallback(
    async (planId: string) => {
      Swal.fire({
        title: '<strong>Confirmação</strong>',

        html: 'Tem certeza que deseja remover esta cobrança?',

        showCancelButton: true,

        cancelButtonText: 'Cancelar',

        focusConfirm: false
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await deletePlan(planId)

            Swal.fire({
              icon: 'success',

              title: 'Sucesso!',

              text: 'Cobrança excluída com sucesso!'
            })

            await getPlans({})
          } catch (error) {
            Swal.fire({
              icon: 'error',

              title: 'Erro',

              text: `Erro ao excluir cobrança. ${
                error.response
                  ? 'Esta cobrança já está associada a um conteúdo!'
                  : error.message
              }`
            })
          }
        }
      })
    },
    [getPlans]
  )

  const inactivatePlan = useCallback(
    async (planId: string, is_active: boolean) => {
      Swal.fire({
        title: '<strong>Confirmação</strong>',
        icon: 'question',
        html: `Tem certeza que deseja ${
          !is_active ? 'ativar' : 'inativar'
        } este plano?`,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        focusConfirm: false
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await updatePlanById(planId, { is_active: is_active })

            Swal.fire({
              icon: 'success',
              title: 'Sucesso!',
              text: `Plano ${
                !is_active ? 'ativado com sucesso!' : 'inativado com sucesso!'
              }`
            })
            await getPlans({})
          } catch (e) {
            Swal.fire({
              icon: 'error',
              title: 'Erro ao inativar o plano',
              text: e.message
            })
          }
        }
      })
    },
    [getPlans]
  )

  const plansToBeShown = useMemo(() => {
    return plans
      ? plans.data.map((plan) => ({
          selectAll: (
            <div
              style={{
                display: 'flex',
                gap: '5px'
              }}
            >
              <Checkbox />
            </div>
          ),
          id: plan.id,
          title: plan.title,
          vantage: plan.advantage,
          price: plan.price,
          active: (
            <div
              style={{
                display: 'flex',
                gap: '5px'
              }}
            >
              {!plan.is_active ? (
                <>
                  <AiOutlineClose size={25} />{' '}
                </>
              ) : (
                <>
                  <AiOutlineCheck size={25} />
                </>
              )}
            </div>
          ),
          actions: (
            <div
              style={{
                display: 'flex',
                gap: '5px'
              }}
            >
              <Button
                className="small danger"
                title="Editar Usuário"
                styleButton="edit"
                onClick={() => editPlan(plan.id)}
              >
                <div>
                  <AiOutlineEdit className="icon-danger" />
                </div>
              </Button>
              <Button
                className="small danger"
                styleButton="attencion"
                title={!plan.is_active ? 'Inativar' : 'Ativar' + ' plano'}
                onClick={() => {
                  inactivatePlan(plan.id, !plan.is_active)
                }}
              >
                <div>
                  <AiOutlineClose className="icon-danger" />
                </div>
              </Button>
              <Button
                className="small danger"
                title="Excluir Usuário"
                styleButton="danger"
                onClick={() => removePlan(plan.id)}
              >
                <div>
                  <BiTrash className="icon-danger" />
                </div>
              </Button>
            </div>
          )
        }))
      : []
  }, [plans, editPlan, removePlan])

  return (
    <Container>
      <BreadCrumb
        crumbs={[
          <Link key={1} to="/home">
            Início
          </Link>,
          <span key={2}>Planos</span>
        ]}
      />

      <PageTitle>Planos</PageTitle>

      <Box padding="0 0 20px 0">
        <Button onClick={createPlan}>CRIAR PLANO</Button>
      </Box>

      <Table
        headersConfig={[
          {
            headerLabel: <Checkbox />,
            propName: 'selectAll'
          },
          {
            headerLabel: <span>Título</span>,
            propName: 'title'
          },
          {
            headerLabel: <span>Vantagens</span>,
            propName: 'vantage'
          },
          {
            headerLabel: <span>Preço</span>,
            propName: 'price'
          },
          {
            headerLabel: <span>Ativo</span>,
            propName: 'active'
          },
          {
            headerLabel: <span>Ações</span>,
            propName: 'actions'
          }
        ]}
        handleChangePage={(page) =>
          getPlans({ take: itensPerPage, page: page })
        }
        actualPage={actualPage}
        totalPages={totalPages}
        setPage={(page) => setPage(page)}
        foot
        itemsToShow={plansToBeShown}
        emptyListMessage={'Não foram encontrados planos cadastradas!'}
      />
    </Container>
  )
}
